/**
  アポ獲得報告用フォームコンポーネント
*/
<template>

  <Header v-cloak></Header>
  <div class="l-wrapper" v-cloak>
    <div class="l-content">

      <Title
      :isAdmin="isAdmin"
      :groupTitle="groupTitle"
      :modeChangeBtnText="modeChangeBtnText"
      :modeChangeBtnLabel="modeChangeBtnLabel"
      :modeStatus="modeStatus"
      :modeChange="modeChange"
      :edit="edit"
      :post="post"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<span v-html="displayName"></span>さん<br>
          以下のフォームに案件情報を入力してください。
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box class="js-notEditable" v-if="formType == 'アポイント運用'">
          <Column>
            <label>アポ種別</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required" name="apType" value="紹介アポ">
                  <span>紹介アポ</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required" name="apType" value="既存顧客からの追加商材アポ">
                  <span>既存顧客からの追加商材アポ</span>
                </label>
              </li>
            </ul>
          </Column>
          <Column>
            <label>紹介種別</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required" name="referralType" value="先方から">
                  <span>先方から</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required" name="referralType" value="SGから打診">
                  <span>SGから打診</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required" name="referralType" value="紹介キャンペーン">
                  <span>紹介キャンペーン</span>
                </label>
              </li>
            </ul>
          </Column>
          <Column>
            <div id="referralCodeForm" class="pd3_parts">
              <label for="name">紹介コード</label>
              <div class="display_yoko">
                <input type="text" id="referralCode" class="required " placeholder="SUNGROVE">
              </div>
            </div>
          </Column>
        </Box>

        <Box>
          <Section v-if="formType == 'アポイント運用'">
            <h1>アポ情報</h1>
          </Section>
          <Column>
            <Column id="subjectsForm" v-if="formType == 'アポイント運用'">
              <label for="name">案件ID</label>
              <div class="width75">
                <div class="display_yoko mWidth240">
                  <input type="text" name="company_name" pattern="^(?!http(s:\/\/.*|:\/\/.*)|^0[0-9]*-[0-9]*-([0-9]{3}|[0-9]{4})).*$" id="subjectID" class="required" placeholder="例:AP111111_111111">
                </div>
              </div>
            </Column>
            <Column v-if="formType == 'アポイント運用'">
              <label for="name">案件名</label>
              <div class="width75">
                <div class="display_yoko mWidth240">
                  <input type="text" name="company_name" pattern="^(?!http(s:\/\/.*|:\/\/.*)|^0[0-9]*-[0-9]*-([0-9]{3}|[0-9]{4})).*$" id="comName" class="required" placeholder="サングローブ株式会社">
                </div>
              </div>
            </Column>
            <Column class="noneDisplay">
              <div class="display_yoko set_btm">
                <span class="chara_bottom">【</span>
                <div class="width45">
                  <input type="text" id="ap_type" placeholder="自動入力されます" readonly>
                </div>
                <span class="chara_bottom">アポ】</span>
              </div>
            </Column>
            <Column class="js-notEditable" v-if="formType != 'アポイント運用'">
              <div class="pd3_parts">
                <p>
                  <label class="c-choice">
                    <input type="checkbox" id="not_confirm_ap"/>
                    <span>前確不要</span>
                    </label>
                </p>
                <Info><Notice>※カレンダータイトルに前確マーク「彡」が自動で付きます。</Notice></Info>
              </div>
            </Column>
          </Column>

          <Column class="js-notEditable" v-if="formType != 'アポイント運用'">
            <label>新規見込み架電</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="callin_prospect" value="新規" data-id="1" required>
                  <span class="required_color">新規</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="callin_prospect" value="見込み" data-id="2">
                  <span class="required_color">見込み</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="callin_prospect" value="代電" data-id="3">
                  <span class="required_color">代電</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column id="field_prospect_day" class="js-notEditable noneDisplay" v-if="formType != 'アポイント運用'">
            <label>初回見込み日時</label>
            <div class="mWidth280">
              <input type="date" id="prospect_day" class="datepicker required_color" value="">
            </div>
          </Column>

          <Column id="field_prospect_person" class="js-notEditable noneDisplay" v-if="formType != 'アポイント運用'">
            <label>代電ID</label>
            <div class="mWidth280">
              <input type="text" id="prospect_person" class="required_color" value="">
            </div>
          </Column>

          <Column class="js-editable" v-if="formType != 'アポイント運用'">
            <label for="name">案件名</label>
            <div class="c-flex">
              <input type="text" name="company_name" pattern="^(?!http(s:\/\/.*|:\/\/.*)|^0[0-9]*-[0-9]*-([0-9]{3}|[0-9]{4})).*$" id="filed1" class="required required_color normalization_text" placeholder="サングローブ株式会社" v-model="editableItems.companyName" required>
              <label class="c-choice js-notEditable" id="cb_outlet">
                <input type="checkbox" id="outlet_flag">
                <span id="center">アウトレット</span>
              </label>
            </div>
          </Column>

          <Column class="js-editable">
            <label for="name">代表者名</label>
            <div class="width60 display_yoko">
              <div class="mWidth280">
                <input type="text" placeholder="山田太郎" id="ceo_name" name="ceo_name" class="normalization_text required required_color" v-model="editableItems.ceoName">
              </div>
            </div>
          </Column>

          <Column id="gender" class="js-notEditable">
            <label>代表性別</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="gender" value="女性" id="gender_women" required>
                  <span class="genderSpan required_color">女性</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="gender" value="男性">
                  <span class="genderSpan required_color">男性</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column id="contact_name_column" class="js-notEditable noneDisplay">
            <label for="name">担当者名</label>
            <div class="width60 display_yoko">
              <div class="mWidth280">
                <input type="text" placeholder="山田太郎" id="contact_name" name="contact_name" class="normalization_text required_color">
              </div>
            </div>
          </Column>

          <Column name="outlet_reasons" class="js-notEditable noneDisplay" id="outlet_reasons">
            <label for="name">アウトレット理由</label>
            <ul class="c-choices">
              <li class="c-choices__item" v-for="(values) in inputOutlet">
                <label class="c-choice">
                  <input type="checkbox" name="outlet" v-bind:value="values.value" v-bind:id="'outlet-' + values.id" class="js-sync-checkbox">
                  <span class="required_color int_required_color">{{ values.value }}</span>
                </label>
              </li>
            </ul>
            <Info><button type="button" class="c-link" @click="isOutletModal = !isOutletModal">選択モーダルを開く</button></Info>
          </Column>

          <Column class="js-editable" v-if="formType != 'アポイント運用'">
            <label for="">郵便番号</label>
            <div class="display_tate mWidth160">
              <input type="tel" class="required required_color" pattern="^[0-9]{3}-?[0-9]{4}$" id="postcode" name="postcode" placeholder="1234567" v-model="editableItems.postcode">
            </div>
          </Column>

          <Column class="js-editable">
            <label for="">所在地</label>
            <div class="display_yoko">
              <div class="width100 add1">
                <input type="text" class="required required_color normalization_text" id="address_all1" name="address_all" placeholder="都道府県・市区町村" pattern="(東京都|北海道|(?:京都|大阪)府|.{2,3}県).*" v-model="editableItems.address">
              </div>
            </div>
          </Column>

          <Column class="js-editable">
            <div id="input_tel">
              <label for="">連絡先電話番号</label>
              <ul class="c-variables">
                <li class="c-variables__item copy_form_tel1 noneDisplay">
                  <input type="text" name="tel" maxlength="13" placeholder="半角数字で入力" class="tel">
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="tel1"></IconAdd>
                    <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="tel1"></IconRemove>
                  </div>
                </li>
              </ul>
            </div>

            <div id="add_del_input_tel1">
              <ul id="f_tel" class="c-variables">
                <li class="c-variables__item">
                  <input type="text" name="tel" placeholder="半角数字で入力" class="required required_color tel" required>
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="tel1"></IconAdd>
                    <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="tel1" disabled></IconRemove>
                  </div>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="js-editable">
            <div id="input_other_tel">
              <label for="">その他の電話番号</label>
              <ul class="c-variables">
                <li class="c-variables__item copy_form_other_tel1 noneDisplay">
                  <input type="text" name="other_tel_input" maxlength="13" placeholder="半角数字で入力" class="other_tel">
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="other_tel1"></IconAdd>
                    <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="other_tel1"></IconRemove>
                  </div>
                </li>
              </ul>
            </div>

            <div id="add_del_input_other_tel1">
              <ul id="f_other_tel" class="c-variables">
                <li class="c-variables__item">
                  <input type="text" name="other_tel_input" placeholder="半角数字で入力" class="other_tel_input">
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="other_tel1"></IconAdd>
                    <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="other_tel1" disabled></IconRemove>
                  </div>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="js-editable">
            <label for="hp_url">HPのURL</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="url_exists" value="無" required>
                  <span class="required_color">無</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="url_exists" value="有">
                  <span class="required_color">有</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="js-editable">
            <ul class="c-variables">
              <li class="copyEle c-variables__item copy_form_url1 noneDisplay">
                <input type="url" class="hp_1" name="hp_url" placeholder="URL" pattern="http(s://.*|://.*)">
                <div class="c-variables__item-controls">
                  <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="url1"></IconAdd>
                  <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="url1"></IconRemove>
                </div>
              </li>
            </ul>

            <div id="add_del_input_url1" class="noneDisplay">
              <ul id="f_hp_url" class="c-variables">
                <li class="c-variables__item">
                  <input type="url" class="hp_1" name="hp_url" id="hp_1" placeholder="URL" pattern="http(s://.*|://.*)">
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="btn-floating btn-small waves-effect waves-light red lighten-3 btn_add mg1" id="url1"></IconAdd>
                    <IconRemove name="del" value="－" class="btn-floating btn-small waves-effect waves-light blue lighten-3 btn_del" id="url1" disabled></IconRemove>
                  </div>
                </li>
              </ul>
            </div>
          </Column>

          <Column id="f_previous_ap" class="js-notEditable" v-if="formType != 'アポイント運用'">
            <label for="previous_ap">掘り起こし</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="previous_ap" value="×" required>
                  <span class="required_color">×</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="previous_ap" value="〇">
                  <span class="required_color">〇</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column id="f_previous_ap_option" class="js-notEditable noneDisplay">
            <Column class="mWidth380">
              <label for="name">掘り起こし元ID</label>
              <div class="c-flex">
                <input type="text" id="subjectsID" placeholder="（例）AP111111_111111" min="22">
                <label class="c-choice">
                  <input type="checkbox" id="unknown_id">
                  <span id="unknown_id_span">不明</span>
                </label>
              </div>
            </Column>
            <Column class="mWidth280">
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="previous_ap_option" value="過去キャンセル・日程不明リスケ案件">
                    <span>過去キャンセル・日程不明リスケ案件</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="previous_ap_option" value="過去失注案件">
                    <span>過去失注案件</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="previous_ap_option" value="詳細不明">
                    <span>詳細不明</span>
                  </label>
                </li>
              </ul>
            </Column>
          </Column>

          <Column id="f_previous_ap_name" class="js-notEditable mWidth280 noneDisplay">
            <label for="f_previous_ap_name">元担当者名</label>
            <input type="text" name="previous_ap_name" id="previous_ap_name" class="normalization_text">
          </Column>

          <Column class="js-notEditable">
            <label>アポ日程</label>
            <Group>
              <div class="mWidth100">
                <label class="c-choice">
                  <input type="checkbox" id="today_ap" />
                  <span>当アポ</span>
                </label>
              </div>
              <div class="mWidth212">
                <input type="date" id="ap_doing_date" class="required required_color" required min="">
              </div>
              <Flex id="ap_time" class="display_yoko">
                <div class="pd4_r width30 mWidth100">
                  <input type="text" class="time start f_time required required_color" id="ap_start_time" placeholder="開始時間" required/>
                </div>
                <div class="width30 mWidth100">
                  <input type="text" class="time end f_time required required_color" id="ap_end_time" placeholder="終了時間" required/>
                </div>
                <div class="mWidth100">
                  <input type="Number" id="duration" placeholder ="所要時間" readonly>
                </div>
                <span>分</span>
              </Flex>
            </Group>
          </Column>

          <Column class="js-hook-suggestion js-editable">
            <div name="f_hook" class="display_yoko pd3_parts">
              <label for="">フック</label>
              <ul class="c-choices">
                <li class="c-choices__item" v-if="formType != 'アポイント運用'">
                  <label class="c-choice">
                    <input type="radio" name="hook" id="hook_w" class="hook_disable " value="W" data-type="ウェル" data-id="2" v-model="hook">
                    <span class="required_color">W</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                  <input type="radio" name="hook" id="hook_i" class="hook_disable " value="イツザイ" data-type="イツザイ" data-id="3"  v-model="hook">
                    <span class="required_color">イツザイ</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="hook" id="hook_j" class="hook_disable " value="助成金Lab" data-type="助成金" data-id="5" v-model="hook">
                    <span class="required_color">助成金Lab</span>
                  </label>
                </li>
                <li class="c-choices__item" v-if="formType != 'アポイント運用'">
                  <label class="c-choice">
                    <input type="radio" name="hook" id="hook_e" class="hook_disable " value="Easier" data-type="Easier" data-id="11" v-model="hook">
                    <span class="required_color">Easier</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="hook" value="なし" id="hook_disable_none" data-type="" data-id="4"  v-model="hook">
                    <span class="required_color">なし</span>
                  </label>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="js-hook-suggestion js-editable">
            <div class="display_yoko pd3_parts" name="f_sgs">
              <label for="">提案内容</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" class="not_itzi" id="s_cms" value="CMS" data-id="1" v-model="suggestion" ref="s_cms">
                    <span class="required_color">CMS</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" class="not_itzi" id="s_ec" value="EC" data-id="2" v-model="suggestion" ref="s_ec">
                    <span class="required_color">EC</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" id="itzi" class="itzi" value="イツザイ" data-id="3" v-model="suggestion" ref="s_itzi">
                    <span class="required_color">イツザイ</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" id="itzi_lite" class="itzi_lite" value="イツザイライト" data-id="8" v-model="suggestion" ref="s_itzi_lite">
                    <span class="required_color">イツザイライト</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" id="s_sub" class="s_sub" value="助成金" data-id="5" v-model="suggestion" ref="s_sub">
                    <span class="required_color">助成金</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" id="seo" value="SEO" v-model="suggestion" ref="s_seo">
                    <span class="required_color">SEO</span>
                  </label>
                </li>
                <li class="c-choices__item" v-if="formType != 'アポイント運用'">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" class="not_itzi" id="s_easier" value="Easier" data-id="11" v-model="suggestion" ref="s_easier">
                    <span class="required_color">Easier</span>
                  </label>
                </li>
                <li class="c-choices__item" v-if="formType == 'アポイント運用'">
                  <label class="c-choice">
                    <input type="checkbox" name="suggestion" id="meo" value="MEO" data-id="7" v-model="suggestion" ref="s_meo">
                    <span class="required_color">MEO</span>
                  </label>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="js-hook-suggestion js-editable" id="giving_name_radio" v-if="formType != 'アポイント運用'">
            <div class="display_yoko pd3_parts">
              <label for="">名乗り</label>
              <ul class="c-choices">
                <li class="c-choices__item giving_itzai">
                  <label class="c-choice">
                    <input type="radio" name="giving_name" id="giving_name_ituzai" class="hook_disable giving_name" value="イツザイ" data-type="イツザイ" data-id="1" v-model="giving_name">
                    <span class="required_color giving_name_required">イツザイ</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                  <input type="radio" name="giving_name" id="giving_name_merit" class="hook_disable giving_name" value="メリット" data-type="メリット" data-id="2" v-model="giving_name">
                    <span class="required_color giving_name_required">メリット</span>
                  </label>
                </li>
                <li class="c-choices__item giving_itzai giving_subsidy">
                  <label class="c-choice">
                  <input type="radio" name="giving_name" id="giving_name_insight" class="hook_disable giving_name" value="インサイト" data-type="インサイト" data-id="3" v-model="giving_name">
                    <span class="required_color giving_name_required">インサイト</span>
                  </label>
                </li>
                <li class="c-choices__item giving_CMS_EC_SEO">
                  <label class="c-choice">
                  <input type="radio" name="giving_name" id="giving_name_seed" class="hook_disable giving_name" value="シード" data-type="シード" data-id="4" v-model="giving_name">
                    <span class="required_color giving_name_required">シード</span>
                  </label>
                </li>
                <li class="c-choices__item giving_CMS_EC_SEO giving_subsidy">
                  <label class="c-choice">
                  <input type="radio" name="giving_name" id="giving_name_sungrove" class="hook_disable giving_name" value="サングローブ" data-type="サングローブ" data-id="5" v-model="giving_name">
                    <span class="required_color giving_name_required">サングローブ</span>
                  </label>
                </li>
              </ul>
            </div>
          </Column>

          <Column class="js-notEditable">
            <label for="">提案金額</label>
            <Group>
              <div class="pd3_subbtn">
                <label class="c-choice">
                  <input type="checkbox">
                  <span class="waves-effect waves-light btn-small sp_btn" name="s_price_btn" value="change" id="s_price_btn">値段幅あり</span>
                </label>
              </div>
              <Flex id="f_suggestion_price" class="display_yoko set_btm">
                <p id="f_suggestion_price_5"></p>
                <div class="c-select mWidth280">

                  <select v-if="formType == 'アポイント運用'" id="sp1_select" name="suggestion_price1" class="width20 required">
                    <option value="" id="sp1_start" disabled selected>--</option>
                    <optgroup label="" name="sp1" id="normal_web">
                      <option value="2" name="added_op">2</option>
                      <option value="2.3" name="added_op">2.3</option>
                      <option value="3" name="added_op">3</option>
                      <option value="3.3" name="added_op">3.3</option>
                      <option value="4" name="added_op">4</option>
                      <option value="4.3" name="added_op">4.3</option>
                      <option value="5" name="added_op">5</option>
                      <option value="5.1" name="added_op">5.1</option>
                      <option value="5.3" name="added_op">5.3</option>
                      <option value="6" name="added_op">6</option>
                      <option value="6.3" name="added_op">6.3</option>
                      <option value="7" name="added_op">7</option>
                      <option value="7.3" name="added_op">7.3</option>
                      <option value="8" name="added_op">8</option>
                      <option value="8.3" name="added_op">8.3</option>
                      <option value="9" name="added_op">9</option>
                      <option value="9.3" name="added_op">9.3</option>
                      <option value="10" name="added_op">10</option>
                      <option value="10.3" name="added_op">10.3</option>
                      <option value="11" name="added_op">11</option>
                      <option value="11.3" name="added_op">11.3</option>
                      <option value="12" name="added_op">12</option>
                      <option value="12.3" name="added_op">12.3</option>
                      <option value="13" name="added_op">13</option>
                      <option value="13.3" name="added_op">13.3</option>
                      <option value="14" name="added_op">14</option>
                      <option value="14.3" name="added_op">14.3</option>
                      <option value="15" name="added_op">15</option>
                      <option value="15.3" name="added_op">15.3</option>
                    </optgroup>
                  </select>

                  <select v-else id="sp1_select" name="suggestion_price1" class="width20 required required_color">
                    <option value="" id="sp1_start" disabled selected>--</option>
                    
                  </select>

                </div>
                <Flex id="sp_nondisplay" class="width20 set_btm noneDisplay Width100">
                  <p class="yoko">〜</p>
                  <div class="c-select">
                    <select id="sp2_select" name="suggestion_price2" class="yoko required_color">
                      <option value="" id="sp2_start" disabled selected>--</option>
                    </select>
                  </div>
                </Flex>
                <p>万×24か月</p>
              </Flex>
            </Group>
          </Column>

          <Column class="js-editable">
            <label for="">カテゴリ</label>
            <div class="c-select">
              <select name="category" id="category" class="required required_color" required v-model="category">
                <option value="" id="cate_start" disabled selected>--カテゴリ選択--</option>
              </select>
            </div>
          </Column>

          <Column class="js-editable">
            <div class="pd3_parts">
              <label for="">業種 </label>
              <div class="c-select">
                <select name="sub_cate" id="sub_cate" required class="width45 required required_color" v-model="sub_cate">
                  <option value="" id="sub_cate_start" disabled selected>--業種 選択--</option>
                </select>
              </div>
              <p class="c-info c-notice">※「その他」選択した場合：備考欄に詳細記載。</p>
            </div>
          </Column>

          <Column>
            <div class="width45 pd3_parts">
              <label for="name">商談形式</label>
              <div class="c-select js-notEditable">
                <select name="bussiness_format" id="bussiness_formats" required class="required required_color">
                  <option value="" disabled selected>--商談形式 選択--</option>
                  <option name="bussiness_format" value="オンライン" data-type="オンライン" data-id="1">オンライン</option>
                  <option name="bussiness_format" value="来社" data-type="来社" data-id="2">来社</option>
                  <option name="bussiness_format" value="カフェ" data-type="対面" data-id="3">カフェ　　※カフェ名記載必須</option>
                  <option name="bussiness_format" value="訪問" data-type="対面" data-id="3">訪問</option>
                </select>
              </div>
              
              <p class="c-notice" v-if="editMessage">リスキャンから変更お願いします</p>
            </div>
          </Column>
          
          <Column v-if="editMessage">
            <div class="width45 pd3_parts">
              <label for="name">商談時間</label>
              <p class="c-notice">リスキャンから変更お願いします</p>
            </div>
          </Column>

          <Column id="visit_area" class="js-notEditable noneDisplay">
            <label for="name">来社エリア</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required required_color" name="area" id="area_kanto" value="東京" data-type="関東">
                  <span class="required_color">東京</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" class="required required_color" name="area" id="area_kansai" value="大阪" data-type="関西">
                  <span class="required_color">大阪</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="area" id="area_nagoya" value="名古屋" data-type="名古屋">
                  <span class="required_color">名古屋</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="area" id="area_hukuoka" value="福岡" data-type="福岡">
                  <span class="required_color">福岡</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="area" id="area_yokohama" value="横浜" data-type="横浜">
                  <span class="required_color">横浜</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="area" id="area_akihabara" value="秋葉原" data-type="秋葉原">
                  <span class="required_color">秋葉原</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column id="f_device"  class="js-notEditable noneDisplay">
            <label for="">使用デバイス</label>
            <div class="c-select">
              <select name="device" id="device" class="required_color">
                <option value="" disabled selected>--使用デバイス選択--</option>
                <option data-id="1">カメラ付きPC</option>
                <option data-id="2">カメラなしPC</option>
                <option data-id="3">タブレット</option>
                <option data-id="4">スマホ</option>
              </select>
            </div>
          </Column>

          <Column id="place_info" class="js-notEditable noneDisplay">
            <Column>
              <label for="">商談住所</label>
              <div class="display_yoko">
                <div class="width100">
                  <input type="text" class="place_info required_color normalization_text" id ="pref_name2" name="pref_name" placeholder="都道府県・市区町村" pattern="(東京都|北海道|(?:京都|大阪)府|.{2,3}県).*">
                </div>
              </div>
            </Column>
            <Column>
              <div class="pd3_parts">
                <label for="">商談場所最寄り駅</label>
                <Flex class="display_yoko set_btm">
                  <div class="width15">
                    <input type="text" id="station" class="required_color normalization_text" placeholder="西新宿">
                  </div>
                  <span class="chara_bottom fs9em">駅 徒歩</span>
                  <div class="width20">
                    <input type="number" id="station_min" min="1" class="required_color" placeholder="半角数字で入力" >
                  </div>
                  <span class="chara_bottom">分</span>
                </Flex>
                <p class="c-info c-notice">※バスを使用する場合の徒歩分数は<br>　バス乗車前の最寄り駅から商談場所までの時間を記載してください</p>
              </div>
            </Column>
            <Column>
              <div class="pd3_parts">
                <label for="">バス</label>
                <Flex class="display_yoko set_btm">
                  <div class="width30">
                    <input type="text" id="bus1" placeholder="乗り場名">
                  </div>
                  <span>～</span>
                  <div class="width30">
                    <input type="text" id="bus2" placeholder="降り場名">
                  </div>
                  <span class="chara_bottom">バス＋徒歩</span>
                  <div class="width20">
                    <input type="number" id="bus3" min="1" placeholder="半角数字で入力">
                  </div>
                  <span class="chara_bottom">分</span>
                </Flex>
                <p class="c-info c-notice">※バス＋徒歩分数は<br>　バス乗車時間および乗車前後の徒歩分数を合計した時間を記載してください</p>
              </div>
            </Column>
          </Column>

          <Column id="filed_neck" class="js-notEditable">
            <label>ネック<Hint href="https://drive.google.com/file/d/1HQqlkH6ViFiCTLbifoA3fS5Ie3BzOoce/view?usp=sharing" target="_blank" rel="noopener noreferrer">HINT!</Hint></label>
            <Group>
              <ul class="c-choices">
                <li class="c-choices__item" v-for="(values) in inputNecks">
                  <label class="c-toggle">
                    <input type="checkbox" class="c-toggle__input js-toggle" v-bind:data-toggle="values.key">
                    <span class="c-toggle__text">{{ values.label }}</span>
                  </label>
                </li>
              </ul>
              <div class="js-toggle-target noneDisplay" v-for="(values) in inputNecks" v-bind:data-toggle-target="values.key">
                <Block>
                  <h4 class="c-label">{{ values.label }}</h4>
                  <ul class="c-choices -vertical" v-if="values.choices">
                    <li class="c-choices__item" v-for="(choice) in values.choices">
                      <label class="c-choice">
                        <input type="checkbox" class="c-choice__input" v-bind:name="choice.name" v-bind:value="choice.text" v-bind:data-neck="values.label" v-bind:data-key="values.key" v-bind:data-id="choice.id">
                        <span class="c-choice__text">{{ choice.text }}</span>
                      </label>
                    </li>
                  </ul>
                  <textarea type="text" id="textarea_sonota" name="neck1" data-neck="その他" data-id="99" v-else></textarea>
                </Block>
              </div>
            </Group>
          </Column>

          <Column v-if="formType != 'アポイント運用'" class="js-notEditable">
            <div class="display_yoko">
              <label for="">
                <Flex>
                  <div data-width-sync-target="mediumName">掲載媒体名</div>
                  <div>掲載媒体 URL</div>
                </Flex>
              </label>
              <ul id="field_medium" class="c-variables js-variables">
                <li class="c-variables__item js-variables-item">
                  <input type="text" name="medium" class="medium_name addDelText"  data-width-sync="mediumName" placeholder="掲載媒体名を入力">
                  <input type="text" name="medium" class="medium_url addDelText" placeholder="掲載媒体URLを入力">
                  <div class="c-variables__item-controls">
                    <IconAdd class="js-item-add"></IconAdd>
                    <IconRemove class="js-item-remove" disabled></IconRemove>
                  </div>
                </li>
              </ul>
            </div>
          </Column>

          <Block class="formWrap js-notEditable" v-if="formType != 'アポイント運用'">
            <div class="rap1_boder" id="">
              <h4 class="c-label">費用前テスクロ</h4>
              <Group>
                <div class="pd3_parts">
                  <h5>有無</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="bfrCostYesNo" id="bfrCostYesNo_show" value="有" data-id="1" required>
                        <span class="required_color bfrCostYesNo_span">有</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="bfrCostYesNo" id="bfrCostYesNo_hide" value="無" data-id="2">
                        <span class="required_color bfrCostYesNo_span">無</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="pd3_parts fild_tescro noneDisplay" id="fildBfrCostGoodBad">
                  <h5>反応</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="tescro" name="bfrCostGoodBad" id="" value="好意的" data-id="1">
                        <span class="bfrCostGoodBad_span int_required_color">好意的</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="tescro" name="bfrCostGoodBad" id="" value="否定的" data-id="2">
                        <span class="bfrCostGoodBad_span int_required_color">否定的</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="tescro" name="bfrCostGoodBad" id="" value="どちらとも言えない" data-id="3">
                        <span class="bfrCostGoodBad_span int_required_color">どちらとも言えない</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="tescro" name="bfrCostGoodBad" id="" value="無" data-id="4">
                        <span class="bfrCostGoodBad_span int_required_color">無</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="tescro" name="bfrCostGoodBad" id="" value="費用前テスクロを実施してない" data-id="5">
                        <span class="bfrCostGoodBad_span int_required_color">費用前テスクロを実施してない</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Group>
            </div>
          </Block>

          <Block class="js-notEditable" v-if="formType != 'アポイント運用'">
            <div class="rap1_boder" id="">
              <h4 class="c-label">費用後テスクロ</h4>
              <Group>
                <div class="pd3_parts">
                  <h5>度合い</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="afrCostType" id="afrCostType_show1" value="弱" data-id="1" required>
                        <span class="required_color">弱</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="afrCostType" id="afrCostType_show2" value="中" data-id="2">
                        <span class="required_color">中</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="afrCostType" id="afrCostType_show3" value="強" data-id="3">
                        <span class="required_color">強</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="" name="afrCostType" id="afrCostType_hide1" value="強" data-id="3">
                        <span class="required_color">無</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="pd3_parts fildAfrCostType fild_tescro noneDisplay">
                  <h5>反応</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp tescro" name="afrCostReaction" id="afrCostReaction_hide1" value="好意的" data-id="1">
                        <span class="int_required_color">好意的</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp" name="afrCostReaction" id="afrCostReaction_hide2" value="アウトあり" data-id="2">
                        <span class="int_required_color">アウトあり</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp" name="afrCostReaction" id="afrCostReaction_show1" value="無" data-id="3">
                        <span class="int_required_color">無</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="pd3_parts fildAfrCostNotReaction fild_tescro noneDisplay">
                  <h5>反応がなかった場合</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp tescro" name="afrCostNotReaction" id="" value="スルーして日程調整" data-id="1">
                        <span class="int_required_color">スルーして日程調整</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp" name="afrCostNotReaction" id="" value="再度テスクロ実施（弱）" data-id="2">
                        <span class="int_required_color">再度テスクロ実施（弱）</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp" name="afrCostNotReaction" id="" value="再度テスクロ実施（中）" data-id="3">
                        <span class="int_required_color">再度テスクロ実施（中）</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="afrCostOp" name="afrCostNotReaction" id="" value="再度テスクロ実施（強）" data-id="4">
                        <span class="int_required_color">再度テスクロ実施（強）</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="pd3_parts fildAfrCostType fild_tescro noneDisplay">
                  <h5>アウトの種類</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp tescro" name="afrCostAutoType" id="" value="費用" data-id="1">
                        <span class="int_required_color">費用</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoType" id="" value="データ" data-id="2">
                        <span class="int_required_color">データ</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoType" id="" value="保証" data-id="3">
                        <span class="int_required_color">保証</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoType" id="" value="タイミング" data-id="4">
                        <span class="int_required_color">タイミング</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoType" id="" value="その他" data-id="99">
                        <span class="int_required_color">その他</span>
                      </label>
                    </li>
                  </ul>
                  <div class="js-other-input-wrap">
                    <input type="text" id="afrCostAutoTypeOther" placeholder="「その他」の詳細を記載">
                  </div>
                </div>
                <div class="pd3_parts fildAfrCostType fild_tescro noneDisplay">
                  <h5>アウトへの対応有無</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp tescro" name="aftCostAutoYesNo" id="" value="有" data-id="1">
                        <span class="int_required_color">有</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="aftCostAutoYesNo" id="" value="無" data-id="2">
                        <span class="int_required_color">無</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="aftCostAutoYesNo" id="" value="その他" data-id="99">
                        <span class="int_required_color">その他</span>
                      </label>
                    </li>
                  </ul>
                  <div class="js-other-input-wrap">
                    <input type="text" id="aftCostAutoYesNoOther" placeholder="「その他」の詳細を記載">
                  </div>
                </div>
                <div class="pd3_parts fild_tescro noneDisplay">
                  <h5>アウトへの着地</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp tescro" name="afrCostAutoResult" id="" value="アウト解消" data-id="1">
                        <span class="int_required_color">アウト解消</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoResult" id="" value="アウト未解消" data-id="2">
                        <span class="int_required_color">アウト未解消</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp afrCostOp" name="afrCostAutoResult" id="" value="その他" data-id="99">
                        <span class="int_required_color">その他</span>
                      </label>
                    </li>
                  </ul>
                  <div class="js-other-input-wrap">
                    <input type="text" id="afrCostAutoResultOther" placeholder="「その他」の詳細を記載">
                  </div>
                </div>
              </Group>
            </div>
          </Block>

          <Block class="js-notEditable" v-if="formType != 'アポイント運用'">
            <div class="rap1_boder" id="">
              <h4 class="c-label">締めトーク</h4>
              <Group>
                <div class="pd3_parts">
                  <h5>有無</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkYesNo" id="closingTalkYesNo_show1" value="有" data-id="1" required>
                        <span class="required_color">有</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkYesNo" id="closingTalkYesNo_hide1" value="無" data-id="2">
                        <span class="required_color">無</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkYesNo" id="other" value="その他" data-id="99">
                        <span class="required_color">その他</span>
                      </label>
                    </li>
                  </ul>
                  <div class="js-other-input-wrap">
                    <input type="text" id="closingTalkYesNoOther" placeholder="「その他」の詳細を記載">
                  </div>
                </div>
                <div class="pd3_parts fild_tescro noneDisplay" id="fildClosingTalkRaction">
                  <h5>反応</h5>
                  <ul class="c-choices">
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp tescro" name="closingTalkRaction" id="" value="好意的" data-id="1">
                        <span class="int_required_color">好意的</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkRaction" id="" value="否定的" data-id="2">
                        <span class="int_required_color">否定的</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkRaction" id="" value="無" data-id="3">
                        <span class="int_required_color">無</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice">
                        <input type="radio" class="otherOp" name="closingTalkRaction" id="" value="その他" data-id="99">
                        <span class="int_required_color">その他</span>
                      </label>
                    </li>
                  </ul>
                  <div class="js-other-input-wrap">
                    <input type="text" id="closingTalkRactionOther" placeholder="「その他」の詳細を記載">
                  </div>
                </div>
              </Group>
            </div>
          </Block>

          <Column id="f_previous_ap" class="js-notEditable" v-if="formType != 'アポイント運用'">
            <label for="sound_source">音源URLの有無</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="sound_source" value="有り" id="sound_source_yes" required>
                  <span class="required_color">有り</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="sound_source" value="無し" id="sound_source_no">
                  <span class="required_color">無し</span>
                </label>
              </li>
            </ul>
          </Column>

          <Block id="ap_sound_source_container" class="js-notEditable" v-if="formType != 'アポイント運用'">
            <div class="ap_sound_source_item">
              <label for="">
                <Flex>
                  <div data-width-sync-target="ap_sound_source">アポ音源URL1</div>
                  <div data-width-sync-target="ap_sound_source">アポ音源の長さ1</div>
                  <div data-width-sync-target="ap_sound_source">音源内容メモ1</div>
                </Flex>
              </label>
              <ul id="field_medium" class="c-variables js-variables">
                <li class="c-variables__item js-variables-item">
                  <input type="text" class="addDelText required_color sound_source_required" data-width-sync="ap_sound_source" placeholder="音源URLを入力">
                  <input type="text" class="addDelText required_color sound_source_required" data-width-sync="ap_sound_source" placeholder="音源の長さを入力">
                  <input type="text" class="addDelText required_color sound_source_required" data-width-sync="ap_sound_source" placeholder="音源内容を入力">
                  <div class="c-variables__item-controls">
                    <IconAdd class="js-sound-source-add"></IconAdd>
                    <IconRemove class="js-sound-source-remove" disabled></IconRemove>
                  </div>
                </li>
              </ul>
              <label>　</label>
            </div>
          </Block>

          <Column class="js-notEditable" id="none_sound_source_reason" v-if="formType != 'アポイント運用'">
            <label for="">音源無し理由</label>
            <input type="text" class="required_color none_sound_source_required" id="none_sound_source_reason_value" placeholder="音源が上がっていない、紹介案件で音源なし 等">
          </Column>

        </Box>

        <Column class="js-editable">
          <label for="">備考</label>
          <textarea class="height80" id="txt_box1" placeholder="メンションは投稿後のコメントからお願いします。
「業種：その他」の場合は「業種→○○(詳細)」の形式で記載してください。"></textarea>
        </Column>

        <div class="pd2 text_center" id="filedBtn">
          <Button class="pulse" id="submit" disabled>slackへ送信</Button>
        </div>

        <div class="pd2">
          <textarea class="Width99 height300 noneDisplay" id="txt_box"></textarea>
        </div>

      </form>

    </div>
  </div>

  <Modal :class="{ '-active': isOutletModal }" :modalTitle="'アウトレット理由選択ヒント'" :modalName="'isOutletModal'">
    <Column class="c-form__item" v-for="(values) in inputOutletCategory">
      <h3 class="c-label">{{ values.label }}</h3>
      <ul class="c-choices -vertical">
        <li class="c-choices__item" v-for="(number) in values.numbers">
          <label v-bind:for="'outlet-' + inputOutlet[number].id" class="c-choice">
            <input type="checkbox" class="c-choice__input" v-bind:data-for="'outlet-' + inputOutlet[number].id">
            <span class="c-choice__text">{{ inputOutlet[number].text }}</span>
          </label>
        </li>
      </ul>
    </Column>
  </Modal>

  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>

  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :calURL="calURL" :message="message" :completeTitle="completeTitle"></Complete>

</template>

<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import "jquery-ui"
  import sha256 from 'crypto-js/sha256'
  import formDataStore from "../../assets/js/formDataStore"
  import formFuncClass from "../../assets/js/formFunctions.class.js"
  import apGainClass from "../../assets/js/apGain.class.js"
  import apGainOperationClass from "../../assets/js/apGainOperation.class.js"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Modal from '../organisms/modal.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';
  import Info from '../molecules/info.vue';

  import Description from '../atoms/description.vue';
  import Notice from '../atoms/notice.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import Toggle from '../atoms/toggle.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';
  import Hint from '../atoms/hint.vue';
  import Link from '../atoms/link.vue';

  export default defineComponent({
    name: 'formApGain',

    components: {
      Header,
      Title,
      Loading,
      Modal,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,
      Info,

      Description,
      Notice,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      Toggle,
      IconAdd,
      IconRemove,
      Hint,
      Link
    },

    data() {
      return {
        initialized: false,
        isLoading: false,
        isComplete: false,
        isProd: false,
        isBody: true,
        loaderText: '',
        groupTitle: '',
        searchURL: '',
        calURL: '',
        message: '',
        completeTitle: '',
        displayName: '',
        isOutletModal: false,
        formType: '',

        editableItems: {},

        isAdmin: false,
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        modeChange: () => {},
        edit: true,
        post: false,
        editMessage: false,

        hook: '',
        suggestion: [],
        giving_name: '',
        category: '',
        sub_cate: '',


        inputOutlet: [
          {
            id: 1,
            value: '⓪',
            text: '⓪ スマホ商談',
          },
          {
            id: 2,
            value: '①',
            text: '① 減額示唆された場合、減額すれば検討の余地がある場合',
          },
          {
            id: 3,
            value: '②',
            text: '② 社内稟議での物理的な即決ネック',
          },
          {
            id: 4,
            value: '③',
            text: '③ 担当へのアポで代表同席予定',
          },
          {
            id: 5,
            value: '④',
            text: '④ 即決即決済即入金までの時間確保が出来なかった場合',
          },
          //⑤は欠番　データベースの番号がズレるため詰めないこと
          {
            id: 6,
            value: '⑤(欠番)',
            text: '⑤「欠番」',
          },
          {
            id: 7,
            value: '⑥',
            text: '⑥ 取締役または理事アポ',
          },
          {
            id: 8,
            value: '⑦',
            text: '⑦ 後継者アポ',
          },
          {
            id: 9,
            value: '⑧',
            text: '⑧ 内部相談での物理的な即決ネック',
          },
          {
            id: 10,
            value: '⑨',
            text: '⑨ 代表が外国人（日本語コミュニケーションに問題なしOR日本人の配偶者同席）',
          },
          {
            id: 11,
            value: '⑩',
            text: '⑩ 65歳以上の高齢者へのアポ',
          },
          {
            id: 12,
            value: '⑪',
            text: '⑪ 外部相談での物理的な即決ネック',
          },
          {
            id: 13,
            value: '⑫',
            text: '⑫ イツザイ60分商談',
          },
          {
            id: 14,
            value: '⑬',
            text: '⑬ 強引にアポ設定をしている',
          },
          {
            id: 15,
            value: '⑭',
            text: '⑭ 費用を掛けてまでの集客/採用を望んでいないと発言があった',
          },
          //⑮は欠番　データベースの番号がズレるため詰めないこと
          {
            id: 16,
            value: '⑮(欠番)',
            text: '⑮「欠番」',
          },
          {
            id: 17,
            value: '⑯',
            text: '⑯ 役員制度のないNo.2へのアポ',
          },
        ],

        inputOutletCategory: [
          {
            label: 'イツザイ/CMS,EC共通',
            numbers: [0, 1, 2, 3, 4, 7, 8, 9, 10, 11, 13, 14],
          },
          {
            label: 'イツザイのみ',
            numbers: [6, 12, 16],
          },
        ],

        inputNecks: [
          {
            key: 'cost',
            label: '費用',
            choices: [
              {
                id: 1,
                name: 'neck1',
                text: '費用案内後、無言になった'
              },
              {
                id: 2,
                name: 'neck1',
                text: 'その額なら難しいと言われた'
              },
              {
                id: 3,
                name: 'neck1',
                text: '明確に「高い」と言われた'
              },
            ],
          },
          {
            key: 'time',
            label: '時間',
            choices: [
              {
                id: 4,
                name: 'neck1',
                text: '90/120分は長いと言われた'
              },
              {
                id: 5,
                name: 'neck1',
                text: '後ろに予定があると言われた'
              },
              {
                id: 6,
                name: 'neck1',
                text: '途中でお客様対応が入ると言われた'
              },
              {
                id: 7,
                name: 'neck1',
                text: '60分商談で、内容次第で時間確保すると言われた'
              },
            ],
          },
          {
            key: 'timing',
            label: 'タイミング',
            choices: [
              {
                id: 8,
                name: 'neck1',
                text: '広告費を掛けたばかりで様子を見たい'
              },
              {
                id: 9,
                name: 'neck1',
                text: 'すぐには始められないと言われた'
              },
            ],
          },
          {
            key: 'soudansya',
            label: '相談者',
            choices: [
              {
                id: 10,
                name: 'neck1',
                text: '共同経営者の同席が未確定'
              },
              {
                id: 11,
                name: 'neck1',
                text: '妻・夫の同席が未確定'
              },
              {
                id: 12,
                name: 'neck1',
                text: '共同経営者の同席ができない'
              },
              {
                id: 13,
                name: 'neck1',
                text: '妻・夫の同席ができない'
              },
              {
                id: 14,
                name: 'neck1',
                text: '税理士に相談したいと言われた'
              },
              {
                id: 15,
                name: 'neck1',
                text: '外部に相談者がいると言われた'
              },
            ],
          },
          {
            key: 'insurance',
            label: '保証',
            choices: [
              {
                id: 16,
                name: 'neck1',
                text: '保証はないのかと言われた'
              },
            ],
          },
          {
            key: 'try',
            label: 'お試し',
            choices: [
              {
                id: 17,
                name: 'neck1',
                text: 'お試しをしたいと言われた'
              },
            ],
          },
          {
            key: 'deta',
            label: 'データ',
            choices: [
              {
                id: 18,
                name: 'neck1',
                text: '他の会社はどれくらい来ているのかわかる資料が欲しいと言われた'
              },
              {
                id: 19,
                name: 'neck1',
                text: 'データがないと検討ができないと言われた'
              },
              {
                id: 20,
                name: 'neck1',
                text: '地域ごとの従業員数や売り上げが必要と言われた'
              },
            ],
          },
          {
            key: 'capa',
            label: 'キャパ',
            choices: [
              {
                id: 21,
                name: 'neck1',
                text: '時期によっては受け入れができないと言われた'
              },
              {
                id: 22,
                name: 'neck1',
                text: '人手が足りていないと言われた'
              },
            ],
          },
          {
            key: '9-bis',
            label: '副業',
            choices: [
              {
                id: 23,
                name: 'neck1',
                text: '代表が学生'
              },
              {
                id: 24,
                name: 'neck1',
                text: 'まだ勤めていて、本業にするかどうか決めていない'
              },
            ],
          },
          {
            key: 'ninshiki',
            label: '商談認識',
            choices: [
              {
                id: 25,
                name: 'neck1',
                text: 'よくわからないからとりあえず来て、と言われた'
              },
              {
                id: 26,
                name: 'neck1',
                text: '話を聞くだけなら、と言われた'
              },
            ],
          },
          {
            key: 'soketu',
            label: '即決',
            choices: [
              {
                id: 27,
                name: 'neck1',
                text: 'その場で決めない、一度持ち帰ると言われた'
              },
              {
                id: 28,
                name: 'neck1',
                text: '稟議が必要と言われた'
              },
            ],
          },
          {
            key: 'web',
            label: 'WEB',
            choices: [
              {
                id: 29,
                name: 'neck1',
                text: '事業内容にWEB事業がある'
              },
              {
                id: 30,
                name: 'neck1',
                text: 'サイトを作りたくないと言われた'
              },
              {
                id: 31,
                name: 'neck1',
                text: 'SEOならいらないと言われた'
              },
            ],
          },
          {
            key: 'keisyo',
            label: '事業継続意欲',
            choices: [
              {
                id: 32,
                name: 'neck1',
                text: 'あとどれくらい事業を続けるかわからないと言われた'
              },
            ],
          },
          {
            key: 'payment',
            label: '支払い方法',
            choices: [
              {
                id: 33,
                name: 'neck1',
                text: 'クレジットカードを持っていないと言われた'
              },
              {
                id: 34,
                name: 'neck1',
                text: 'クレジットカードでは決済しないと言われた'
              },
              {
                id: 35,
                name: 'neck1',
                text: '現金一括なら難しいと言われた'
              },
              {
                id: 36,
                name: 'neck1',
                text: '今期の予算は使い切ったと言われた'
              },
            ],
          },
          {
            key: 'sunnari',
            label: 'すんなりアポ',
            choices: [
              {
                id: 37,
                name: 'neck1',
                text: '相槌のみで、反応が薄かった'
              },
              {
                id: 38,
                name: 'neck1',
                text: '大きなアウトなく、費用にも特に反応がなかった'
              },
            ],
          },
          {
            key: 'sonota',
            label: 'その他',
          },
        ]
      }
    },

    methods: {
      init() {
        // console.log('フォーム初期化開始')
        this.isComplete = false
        this.isLoading = false

        // このフォームの名前とかtalknoteの投稿用アドレスなどの情報格納
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]

        // タイトル表示
        this.groupTitle = this.thisFormParams[0]

        // フォームが本番かdemoか
        this.isProd = this.groupTitle.indexOf('demo_') == -1

        // ユーザー名表示
        // メアドとかもthis.store.state.userDataに入ってる
        this.displayName = this.store.state.userData.displayName

        // 投稿先のtalknoteノートURL
        // console.log('投稿先のtalknoteノートURL', this.thisFormParams[1])

        // talknote投稿用アドレス
        // console.log('talknote投稿用アドレス', this.thisFormParams[2])

        // 表示するフォームのタイプ
        // console.log('表示するフォームのタイプ', this.thisFormParams[3])
        this.formType = this.thisFormParams[3];

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }

        // 外部にあるフォームで使う汎用関数インスタンス化
        const func = new formFuncClass.formFunctions(moment, $, sha256, this)

        // 同じコンポーネントに遷移するときの初期化はここまで
        /*if(this.initialized) {
          func.clearAllOption()
          return
        }*/
        document.documentElement.scrollTop = 0

        // 外部にあるこのフォームで使う関数インスタンス化（しなくてもいいのでまかせます）
        //ここで実行
        if( this.formType == 'アポイント運用') {
          new apGainOperationClass.apGainOperation(this, $, func, moment);
        }
        else {
          new apGainClass.apGain(this, $, func, moment);
        }

        // 初期化完了フラグ
        this.initialized = true
        console.log('initialized')
      },

      viewLoader(loaderText = '') {
        if(
            (!this.loaderText && !loaderText) ||
            (this.isLoading && loaderText == '')
          ) {
          this.isLoading = false
          this.loaderText = ''
          return 
        }
        this.isLoading = true
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {

        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.calURL = param.calURL;
        this.message = param.message;
        this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了';
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },

      getCategory() {
        return this.category;
      },

    },

    watch: {
      // hookが変更されたときに実行
      hook(new_val) {
        if(new_val != '助成金Lab') {
          this.suggestion = []
        }
        this.giving_name = ''
        this.category = ''
        this.sub_cate = ''
      },
      // suggestionが変更されたときに実行
      suggestion(new_val, old_val) {
        const current_val = new_val[new_val.length - 1]

        let index = []
        switch(current_val) {
          case 'CMS': {
            index.push(new_val.indexOf('EC'))
            index.push(new_val.indexOf('Easier'))
            break
          }
          case 'EC': {
            index.push(new_val.indexOf('CMS'))
            index.push(new_val.indexOf('Easier'))
            break
          }
          case 'イツザイ': {
            index.push(new_val.indexOf('イツザイライト'))
            break
          }
          case 'イツザイライト': {
            index.push(new_val.indexOf('イツザイ'))
            break
          }
          case 'SEO': {
            index.push(new_val.indexOf('Easier'))
            break
          }
          case 'Easier': {
            index.push(new_val.indexOf('CMS'))
            index.push(new_val.indexOf('EC'))
            index.push(new_val.indexOf('SEO'))
            break
          }
        }

        // 有効なインデックスのみでループ処理
        index
        .filter(idx => idx !== -1)
        .sort((a, b) => b - a)
        .forEach(idx => {
          if (current_val !== '助成金') {
            this.suggestion.splice(idx, 1)
          }
        })
        this.category = ''
        this.sub_cate = ''
      },
      // giving_nameが変更されたときに実行
      giving_name() {
        this.category = ''
      },
      // categoryが変更されたときに実行
      category() {
        this.sub_cate = ''
      },
    },

    mounted() {
      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {
        // このフォームに直接飛んできたときにフォーム情報取得が完了してないことがあるので同期待ち
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            this.init()
          }
        )
      } else {
        this.init()
      }
    },

    // 同じコンポーネント内で遷移するので以下の記述をした
    /*async beforeRouteUpdate(to, from, next) {
      console.log(this)
      await this.init();
      await next();
    },*/

    setup(_, context) {
      return {}
    }
  });
</script>

<style scoped>
#f_suggestion_price_5:empty {
  display: none;
}

.neck .other {
  display: none;
}

.mWidth100 {
  max-width: 100px;
}

.mWidth160 {
  max-width: 160px;
}

.mWidth212 {
  max-width: 212px;
}

.mWidth240 {
  max-width: 240px;
}

.mWidth280 {
  max-width: 280px;
}

.mWidth380 {
  max-width: 380px;
}

.Width99 {
  width: 99%;
}

.Width100 {
  width: 100%;
}

.height80 {
  height: 80px;
}

.height300 {
  height: 300px;
}

.noneDisplay {
  display: none;
}
</style>
