/**
  申し込み用フォーム[イツザイ]コンポーネント
*/
<template>
<div id="formItzai" class="form_group">
  <Section class="form_wrap form_list">
    <div class="rap1_boder" id="wrap3">
      <Section><h1 class="wrap_title pd_7">イツザイ</h1></Section>
      <div class="formSharedComm">
        <Column class="pd3_parts">
          <Section><label class="c-notice">納期はヒアリング日から "2ヶ月" としてください（※契約ではなく、ヒアリング）</label></Section>
          <label>動画撮影</label>
          <p class="c-notice">※有の場合は安井大侑さん・瀬川文彦さんをメンションつけてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="movie_yesNo" id="movie_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="movie_yesNo" id="movie_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formItzai">
          <label>プラン</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="itzai_plan" id="itzai_plan_super_lite" value="スーパーライト">
                <span class="">スーパーライト</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="itzai_plan" id="itzai_plan_semi_lite" value="セミライト">
                <span class="">セミライト</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="itzai_plan" id="itzai_plan_lite" value="ライト">
                <span class="">ライト</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="itzai_plan" id="itzai_plan_standard" value="スタンダード">
                <span class="">スタンダード</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="itzai_plan" id="itzai_plan_complete" value="コンプリート">
                <span class="">コンプリート</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm" id="brand_page">
          <label>ブランドページ</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_itzai" id="Brand_page_yes_itzai" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_itzai" id="Brand_page_no_itzai" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formItzai">
          <label>PRページ制作代行本数</label>
          <p class="-attention">※今回作成する数を記入してください。追加ではないので注意</p>
          <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="PR_pages_produced_itzai">
        </Column>
        <Column class="pd3_parts formItzai">
          <label>募集要項制作代行数</label>
          <p class="-attention">※今回作成する数を記入してください。追加ではないので注意</p>
          <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="recruitment_requirements_produced_itzai">
        </Column>
        <Column class="pd3_parts formItzai">
          <label>KV追加制作代行数</label>
          <p class="-attention">※サービス内容（4）に記載している数を記入してください。ない方は0と記載ください</p>
          <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="KV_produced_itzai">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label >業界</label>
          <div class="c-select">
            <select name="industry_names" id="industry_names" class="required form_select">
              <option value="" disabled selected>--業種 選択--</option>
            </select>
          </div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>募集職種名</label>
          <input type="text" id="rctJobCate" class="form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>補償オプション</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="compensation_yesNo" id="compensation_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice formSharedComm">
                <input type="radio" class="required" name="compensation_yesNo" id="compensation_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>その他オプション</label>
          <input type="text" id="otherOp" class="form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>撮影有無</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo" id="shoot_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo" id="shoot_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="fildReasonNotShoot" class="pd3_parts formSharedComm -none">
          <label>撮影無し理由</label>
          <input type="text" id="reasonNotShoot">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>契約動機</label>
          <label class="c-choice documents"><input type="checkbox" class="required" name="motive_signing_contract" value="慢性的人員不足の解消"><span>慢性的人員不足の解消</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="欠員"><span>欠員</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="増員"><span>増員</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="新店を増やすにあたってのスタートアップメンバーの募集"><span>新店を増やすにあたってのスタートアップメンバーの募集</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="管理者・経験者・資格保有者などの採用難易度が高い採用をしたい"><span>管理者・経験者・資格保有者などの採用難易度が高い採用をしたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="現場の若返りを図りたい"><span>現場の若返りを図りたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="今よりも採用コストを下げていきたい"><span>今よりも採用コストを下げていきたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="応募者の質を改善したい"><span>応募者の質を改善したい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="経営に専念するための人員確保"><span>経営に専念するための人員確保</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="応募者情報を管理できる管理システムが欲しかった"><span>応募者情報を管理できる管理システムが欲しかった</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="イツザイ導入企業から実績を聞いての紹介"><span>イツザイ導入企業から実績を聞いての紹介</span></label>
          <label class="c-choice documents"><input type="checkbox" name="motive_signing_contract" value="その他"><span>その他</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>契約動機（上記選択では共有に足りない内容がある場合やその他選択をした場合に共有ください）</label>
          <div><textarea id="contractReason" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>顧客人柄</label>
          <p class="-attention">※カバーしなければいけない要素を確認させていただきたいです。担当のアサインの際に活用します。</p>
          <label class="c-choice documents"><input type="checkbox" class="required" name="persnality" value="採用リテラシー高い"><span>採用リテラシー高い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="採用リテラシー低い"><span>採用リテラシー低い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="機械操作が苦手"><span>機械操作が苦手</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="非協力的"><span>非協力的</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="こだわり強い"><span>こだわり強い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="こだわりなし＆任せたい"><span>こだわりなし＆任せたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="こだわりなし＆教えてほしい"><span>こだわりなし＆教えてほしい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="プライド高い・相手を馬鹿にする"><span>プライド高い・相手を馬鹿にする</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="極端な考え方をする・思い込み激しい"><span>極端な考え方をする・思い込み激しい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="反応薄い"><span>反応薄い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="persnality" value="高齢・前の話を忘れてしまう"><span>高齢・前の話を忘れてしまう</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>お客様との会話内容（世間話を含めて）</label>
          <p class="-attention">※こういう話題してもらった方がアイスブレイクになるなどがあれば教えてほしいです！<br>
            （例）<br>
            車好きの代表です。外車の話をしたらすごく盛り上がりました。<br>
            リテラシーが高いので知見提供し続けたり業界あるある話をして信用を得ると本音を引き出しやすくなりました。など
          </p>
          <div><textarea id="conv" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>イツザイに載せる内容について何かお客様とお約束したり、お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice documents"><input type="checkbox" name="promise_insertion" value="訴求方針について"><span>訴求方針について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_insertion" value="運用方針について"><span>運用方針について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_insertion" value="掲載メディアについて"><span>掲載メディアについて</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_insertion" value="写真や動画について"><span>写真や動画について</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>上記の詳細</label>
          <div><textarea id="convItzai" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>イツザイのサービス内容について何かお客様とお約束したり、お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="リリース後の修正について"><span>リリース後の修正について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="撮影について"><span>撮影について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="運用サポートについて"><span>運用サポートについて</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="掲載時期について"><span>掲載時期について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="キャンペーンについて"><span>キャンペーンについて</span></label>
          <label class="c-choice documents"><input type="checkbox" name="promise_contents" value="追加費用について"><span>追加費用について</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>上記の詳細</label>
          <div><textarea id="convService" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>掲載希望日時（ヒアアポ～の期間・撮影期間を伝えた上で）</label>
          <div class="width60"><input type="date" id="shootDate" class="required form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>求人背景（他媒体効果など）</label>
          <div class="width60"><input type="text" id="rctBackground" class="form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>どういう人が取りたいか</label>
          <div><textarea id="kind_person_Hope" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>その他顧客希望</label>
          <div><textarea id="otherHope" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>主要連絡先は共通記入欄と異なりますか？</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label for="main_contact_itzai_yes" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="main_contact_itzai" value="はい" id="main_contact_itzai_yes">
                <span>はい</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label for="main_contact_itzai_no" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="main_contact_itzai" value="いいえ" id="main_contact_itzai_no">
                <span>いいえ</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="is_contact_itzai_concurrent" class="pd3_parts formSharedComm">
          <label>主要連絡先情報備考</label>
          <div><textarea id="contact_itzai" class="form_txt"></textarea></div>
        </Column>
        <Column id="is_contact_itzai_presence">
          <label for="name">主要連絡先情報備考に記載した方の商談同席有無</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label for="main_contact_itzai_presence_yes" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="main_contact_itzai_presence" value="同席していた" id="main_contact_itzai_presence_yes">
                <span>同席していた</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label for="main_contact_itzai_presence_no" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="main_contact_itzai_presence" value="同席していない" id="main_contact_itzai_presence_no">
                <span>同席していない</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column>
          <label for="name">求人ボックスキャンペーン対応</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label for="guide_job_offer_campaign_yes" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="有" id="guide_job_offer_campaign_yes">
                <span>有</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label for="guide_job_offer_campaign_no" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="無" id="guide_job_offer_campaign_no">
                <span>無</span>
              </label>
            </li>
          </ul>
        </Column>
      </div>
    </div>
  </Section>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationItzai',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
