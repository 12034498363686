<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :groupTitle="groupTitle"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <div id="requestForms" class="form_ap_area">
        <Box>
          <Column>
            <label>フォームタイプ</label>
            <div class="c-select">
              <select id="formTypeId">
                <option value="選択" disabled selected>--選択--</option>
                <option class="required requiredOP" name="formType" id="btnForm_Request" value="申請">申請</option>
                <option class="required requiredOP" name="formType" id="btnForm_Change" value="変更">変更</option>
                <option class="required requiredOP" name="formType" id="btnForm_Cancel" value="取り消し（休日・休日出勤）">取り消し（休日・休日出勤）</option>
              </select>
            </div>
          </Column>

          <Column class="requestType">
            <label>申請タイプ</label>
            <Column>
              <ul id="requestForm" class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice" for="btnRequest_Rest">
                    <input id="btnRequest_Rest" class="required requiredOP" type="radio" name="requestForm" value="おやすみ申請">
                    <span>おやすみ申請</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice" for="btnRequest_HoliWork">
                    <input id="btnRequest_HoliWork" class="required requiredOP" type="radio" name="requestForm" value="休日出勤">
                    <span>休日出勤</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice" for="btnRequest_adjustment">
                    <input id="btnRequest_adjustment" class="required requiredOP" type="radio" name="requestForm" value="アポ調整依頼（平日のみ）">
                    <span>アポ調整依頼（平日のみ）</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="pd3_parts" id="feild_FollowerName">
              <label class="c-choice">
                <input id="agent" class="agent" type="checkbox" value="代理投稿">
                <span>代理投稿</span>
              </label>
              <div class="agentName">
                <input id="agentName" class="required requiredOP" value="">
                <label class="attention">（苗字 or ビジネスネームを入力すると検索開始します）</label>
              </div>
            </Column>
          </Column>
        </Box>

        <Box class="requestKindsBox requestKinds">
          <Section class="requestKinds"><h1>おやすみ申請</h1></Section>
          <Column class="requestKinds">
            <label>おやすみ種別</label>
            <ul id="requestForm" class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice"  for="btnReqKind_WithPay">
                  <input  id="btnReqKind_WithPay" class="required requiredOP" type="radio" name="restType" value="有給">
                  <span>有給</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice" for="btnReqKind_ComHoliday">
                  <input id="btnReqKind_ComHoliday" class="required requiredOP" type="radio" name="restType" value="代休">
                  <span>代休</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice" for="btnReqKind_Holiday">
                  <input id="btnReqKind_Holiday" class="required requiredOP" type="radio" name="restType" value="通常のお休み">
                  <span>通常のお休み</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="compensatoryHolidayTypeBox">
            <label>代休の種類</label>
            <ul id="compensatoryHolidayType" class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input class="required requiredOP" id="compensatoryHoliday" type="radio" name="compensatoryHolidayType" value="土日祝商談の代休">
                  <span>土日祝商談の代休</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice" >
                  <input class="required requiredOP" id="compensatoryHolidayCall" type="radio" name="compensatoryHolidayType" value="架電業務の代休">
                  <span>架電業務の代休</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column id="searchID" class="searchID">
            <label for="name">案件IDで案件検索</label>
            <div class="display_yoko">
              <input type="text" id="SubjectId" placeholder="（例）AP111111_111111" min="22">
            </div>
            <div id="result_api">
              <p id="status_comment" class="c-notice">代休理由の案件IDを入れてください</p>
            </div>
            <div class="display_tate" id="result_titles">
            </div>
            <label>　</label>
            <div class="display_tate">
              <label>案件日付</label>
              <input type="text" id="salesdate" placeholder="手入力不可  案件検索で自動入力" readonly>
            </div>
          </Column>

          <Column class="requestKinds">
            <label>時間</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice"  for="btnTime_AllDay">
                  <input id="btnTime_AllDay" class="required requiredOP" type="radio" name="timeKinds" value="終日">
                  <span>終日</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice"  for="btnTime_AM">
                  <input id="btnTime_AM" class="required requiredOP" type="radio" name="timeKinds" value="午前休">
                  <span>午前休</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice"  for="btnTime_PM">
                  <input id="btnTime_PM" class="required requiredOP" type="radio" name="timeKinds" value="午後休">
                  <span>午後休</span>
                </label>
              </li>
            </ul>
          </Column>

          <div class="requestStart">
            <label class="error_msg_pref">1日だけの申請の場合は開始日のみの入力でOKです</label>

            <Column>
              <label>開始日付</label>
              <input id="start_date" class="required requiredOP" type="date" min=start_date>
            </Column>

            <Column>
              <label>終了日付</label>
              <input id="last_date" type="date" min=last_date>
            </Column>
          </div>

          <div class="requestDay"> 
            <Column>
              <label>日付</label>
              <input type="date" class="required requiredOP" id="requestDate">
            </Column>
          </div>

          <Column id="reason_holiday" class="requestKinds pd3_parts">
            <label>理由</label>
            <div class="c-select">
              <select id="reason">
                <option value="選択" disabled selected>--選択--</option>
                <option class="required requiredOP" name="reasonType" value="体調不良">体調不良</option>
                <option class="required requiredOP" name="reasonType" value="家庭の事情">家庭の事情</option>
                <option class="required requiredOP" name="reasonType" value="役所・書類の手続き">役所・書類の手続き</option>
                <option class="required requiredOP" name="reasonType" value="冠婚葬祭・法事">冠婚葬祭・法事</option>
                <option class="required requiredOP" name="reasonType" value="有給消化">有給消化</option>
                <option class="required requiredOP" name="reasonType" value="その他">その他</option>
              </select>
            </div>
          </Column>
        </Box>

        <Box class="holidayWorkBox holidayWork">
          <label id="alert" class="alert attention">土曜日・日曜日・祝日のいずれかを選択してださい</label>
          <Column class="holidayWorkBox" id="form_ap1">
            <div class="holidayWork">
              <div class="pd3_parts rap1_boder">

                <div class="display_yoko">
                  <div>
                    <label>日付</label>
                    <div>
                      <input type="date" class="required requiredOP workDate" id="workDate1">
                    </div>
                  </div>

                  <div class="pd_left c-choice">
                    <label>
                      <label>終日</label>
                      <input id="allDay1" class="allDay1" type="checkbox" value="終日">
                      <span></span>
                    </label>
                  </div>
                </div>

                <div class="pd_top20">
                  <label>商談形式</label>
                  <div class="display_yoko">
                    <div class="c-choices__item">
                      <label class="c-choice" id="btnLabel_a" for="holidayWorkBtn_a1">
                        <input id="holidayWorkBtn_a1" class="required requiredOP sellFormat" type="radio" name="sellFormat1" value="対面">
                        <span>対面</span>
                      </label>
                    </div>
                    <div class="pd_left20">
                      <label class="c-choice" id="btnLabel_b" for="holidayWorkBtn_b1">
                        <input id="holidayWorkBtn_b1" class="required requiredOP sellFormat" type="radio" name="sellFormat1" value="オンライン">
                        <span>オンライン</span>
                      </label>
                    </div>
                    <div class="pd_left20">
                      <label class="c-choice" id="btnLabel_c" for="holidayWorkBtn_c1">
                        <input id="holidayWorkBtn_c1" class="required requiredOP sellFormat" type="radio" name="sellFormat1" value="どちらでも可">
                        <span>どちらでも可</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="pd_top20">
                  <label>時間</label>
                  <div id="timePicker" class="display_yoko">
                    <div class="pd_right">
                      <input id="start_time_id1" class="startTime required requiredOP addDelText start_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="start_time" placeholder="開始時間" value="" autocomplete="off">
                    </div>
                    <div>
                      <input id="last_time_id1" class="lastTime addDelText last_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="last_time" placeholder="終了時間" value="" autocomplete="off">
                    </div>
                  </div>
                </div>

              </div>

              <div class="add_bottom">
                <IconAdd type="button" name="add" value="＋" class="c-icon-add holidayWork form_add btn-floating btn-small waves-effect waves-light lighten-3 mg1 formPayment_option"></IconAdd>
                <IconRemove type="button" name="del" value="－" class="c-icon-remove holidayWork form_del btn-floating btn-small waves-effect waves-light lighten-3 formPayment_option"></IconRemove>
              </div>

            </div>
          </Column>
        </Box>

        <Box class="adjustmentBox adjustment">
          <Section><h1>アポ調整依頼（平日のみ）</h1></Section>
          <label id="alert_adjustment" class="alert_adjustment attention">平日を選択してださい</label>
          <div class="adjustment"> 
            <Column>
              <label>日付</label>
              <input type="date" class="required requiredOP" id="adjustmentRequestDate">
            </Column>
          </div>

          <div class="pd_top20">
            <div class="display_yoko">
              <div>
                <label class="c-choice" for="adjustment_sales_type">
                  <input id="adjustment_sales_type" type="checkbox" value="商談形式">
                  <span>対面とオンラインで指定時間が変わる場合<br>（指定時間が同じ場合は✅を入れないでください）</span>
                </label>
              </div>
            </div>
          </div>

          <div class="pd_top20 sales_type_off">
            <label>希望時間（片方のみ指定も可）</label>
            <div id="timePicker" class="display_yoko">
              <div class="pd_right">
                <input id="start_time_id_adjustment" class="startTime requiredOP addDelText start_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="start_time" placeholder="初回アポ開始時間" value="" autocomplete="off">
              </div>
              <div>
                <input id="last_time_id_adjustment" class="lastTime requiredOP addDelText last_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="last_time" placeholder="最終アポ開始時間" value="" autocomplete="off">
              </div>
            </div>
          </div>

          <div class="pd_top20 sales_type_on">
            <label>対面 希望時間</label>
            <div id="timePicker" class="display_yoko">
              <div class="pd_right">
                <input id="start_time_id_adjustment_face" class="startTime requiredOP addDelText start_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="start_time" placeholder="初回アポ開始時間" value="" autocomplete="off">
              </div>
              <div>
                <input id="last_time_id_adjustment_face" class="lastTime requiredOP addDelText last_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="last_time" placeholder="最終アポ開始時間" value="" autocomplete="off">
              </div>
            </div>
          </div>

          <div class="pd_top20 sales_type_on">
            <label>オンライン 希望時間</label>
            <div id="timePicker" class="display_yoko">
              <div class="pd_right">
                <input id="start_time_id_adjustment_online" class="startTime requiredOP addDelText start_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="start_time" placeholder="初回アポ開始時間" value="" autocomplete="off">
              </div>
              <div>
                <input id="last_time_id_adjustment_online" class="lastTime requiredOP addDelText last_time_timepicker time_picker time_picker_0 ui-timepicker-input max_width150px" type="text" name="last_time" placeholder="最終アポ開始時間" value="" autocomplete="off">
              </div>
            </div>
          </div>

          <Column class="pd3_parts">
            <label>理由</label>
            <textarea id="reason_adjustment" class="required requiredOP textarea_em"></textarea>
          </Column>
        </Box>

        <Box class="changesForm">
          <Column>
            <div class="display_yoko">
              <label>変更内容：</label>
              <label class="attention">変更する日・変更内容を入力してください</label>
            </div>
            <textarea id="changes_id" class="required requiredOP textarea_em"></textarea>
          </Column>
        </Box>

        <Box class="cancelForm">
          <Column v-model="isInputCancelDay">
            <div class="display_yoko">
              <label>取り消し内容：</label>
              <label class="attention" v-text="cancelDateDescription"></label>
            </div>
            <div v-if="eventData.length > 0" class="pd_top20">
              <span v-if="eventData.length > 1">選択した日付 <strong>{{ cancelDate }}</strong> に登録されたイベントが複数あります<br>選択し送信ボタンをクリックしてください</span>

              <span v-if="eventData.length == 1">選択した日付 <strong>{{ cancelDate }}</strong> に登録されたイベントが一つ該当しています<br>確認、選択し送信ボタンをクリックしてください
              </span>
              <ul id="requestForm" class="c-choices pd_top20">
                <li class="c-choices__item" v-for="(item, index) in eventData" :key="index">
                  <div v-if="Array.isArray(item.summary)">
                    <ul class="c-choices">
                      <li class="c-choices__item" v-for="(subItem, subIndex) in item.summary" :key="item.id + subIndex">
                        <label class="c-choice" :for="item.id + subIndex">
                          <input :id="item.id + subIndex" :value="item.id + '_sub' + subIndex" :text="subItem" class="required requiredOP" type="radio" name="cancelDates" v-model="cancelEventID" @change="getText($event)">
                          <span>{{ item.active_type + subItem }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <label class="c-choice" :for="item.id">
                      <input :id="item.id" :value="item.id" class="required requiredOP" type="radio" name="cancelDates" v-model="cancelEventID">
                      <span>{{ item.active_type + item.summary }}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="eventData.length == 0">
              <div class="display_yoko pd_top20">
                <div>
                  <label>日付</label>
                  <div>
                    <input type="date" class="required requiredOP cancelDate" id="cancelDate" v-model="cancelDate">
                  </div>
                </div>
              </div>
            </div>
            <div class="pd_top20">
              <label>内容</label>
              <textarea id="cancel_detail_id" class="required requiredOP textarea_em" v-model="cancelDetail"></textarea>
            </div>
          </Column>
        </Box>

        <Column class="remarks pd3_parts hide_holiday">
          <label>備考</label>
          <textarea id="remarks" class="textarea_em"></textarea> 
        </Column>

        <div class="pochiButton">
          <Button v-text="btnLabel" class="waves-effect waves-light btn disabled" id="btn"></Button>
        </div>
      </div>
    </div>
  </div>

  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :completeTitle="completeTitle" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :calURL="calURL" :message="message" :calendarMessage=" calendarMessage"></Complete>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .textarea_em {
    height: 6em;
  }

  .mg1 {
    margin: 0px 10px;
  }

  .pd {
    padding: 20px;
    font-weight: bold;
  }

  .pd3_parts {
    padding: 16.5px 0px 0px 0px;
  }

  .pd_top {
    padding-top: 12px;
  }

  .pd_top20 {
    padding-top: 20px;
  }

  .pd_bottom12 {
    padding-bottom: 12px;
  }

  .width30 {
    width: 30%;
  }

  .max_width150px {
    max-width: 150px;
  }

  .rap1_boder {
    border: 1px solid;
    border-radius: 10px;
    border-color: #eeeeee;
    padding: 14px;
  }

  .pd_left {
    padding-left: 45px;
  }

  .pd_left20 {
    padding-left: 20px;
  }
  .pd_right {
    padding-right: 40px;
  }

  .display_yoko {
    display: flex;
  }

  .add_bottom{
    display: flex;
    padding-top: 6px;
    padding-bottom: 18px;
    justify-content: center;
  }

  .attention {
    color: #e91e63;
  }

  .requestKindsBox,
  .holidayWorkBox,
  .adjustmentBox,
  .remarks {
    display: none;
  }
</style>

<script>
  //専用CSS読み
  import '../../assets/scss/libs/jquery.timepicker.scss';
  import '../../assets/js/libs/jquery.timepicker.min.js';
  import moment from "moment" 
  import $ from 'jquery' 
  import "jquery-ui" 
  import sha256 from 'crypto-js/sha256' 
  import formFuncClass  from "../../assets/js/formFunctions.class" 
  import RequestFunc  from "../../assets/js/requestShift.class"
  import RequestBody from "../../assets/js/requestBody.class"
  
  import axios from 'axios';
  import { defineComponent } from 'vue';
  import formDataStore from "@/assets/js/formDataStore"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  const holidayApi = "https://tn-post-341508.an.r.appspot.com/" //祝日取得API

  export default defineComponent({
    name: 'fromRequestShift',

    components: {
      Header,
      Title,
      Loading,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    data() {
      return {
        initialized: false,
        isMain: true,
        isList: true,
        isForm: false,
        isMessage: true,
        isGroupTitle: false,
        isLoading: false,
        isComplete: false,
        version: '',
        talknoteGroupAddress: '',
        spreadsheetID: '',
        displayName: '',
        groupTitle: '',
        groupURL: '',
        groupID: '',
        groupList: [],
        userName: '',
        userID: '',
        postID: '',
        searchURL: '',
        calURL: '',
        message: '',
        resetFunction: {},
        formType: '',
        loaderText: '投稿中',
        btnLabel: '要望を送信',
        post_url: "", 
        user: {},
        csvData: [],
        isInputCancelDay: true,
        cancelDate: '',
        cancelDetail: '',
        cancelDateDescription: '取り消しする日を選択してください',
        eventData: [],
        cancelEventID: '',
        selectedText: '',
        calendarMessage: ''
      }
    },

    methods: {
      init() {
        this.isComplete = false
        this.isLoading = false

        //初期設定・ページ内全項目非表示 
        $('select').css('display', 'block')
        this.functions = new formFuncClass.formFunctions(moment, $, sha256)
        const that = this 
        this.requestFunc = new RequestFunc.requestFunc()
        this.requestBodyFunc = new RequestBody.requestBody()
        this.clCount = 1 
        this.delCount = 0 
        this.$cloneTag = "" 
        this.$operationTimeTag = "" 

        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]
        this.version = this.store.state.version
        this.groupTitle = this.thisFormParams[0]

        //this.talknoteGroupAddress = this.thisFormParams[2]
        this.displayName = formDataStore.state.userData.displayName
        this.userName = this.displayName
        this.userID = formDataStore.state.userData.userID
        this.userEmail = formDataStore.state.userData.email
        this.$operationTimeTag = $(`#form_ap${this.clCount}`)
        this.requestFunc.checkTime(this.$operationTimeTag, this.clCount)
        this.functions.startEndTime("start_time_id1", "last_time_id1")
        this.functions.startEndTime("start_time_id_adjustment", "last_time_id_adjustment")
        this.functions.startEndTime("start_time_id_adjustment_face", "last_time_id_adjustment_face")
        this.functions.startEndTime("start_time_id_adjustment_online", "last_time_id_adjustment_online")
        this.requestFunc.allReset(this.clCount, this.delCount)
        $('.compensatoryHolidayTypeBox').hide()
        $('#searchID').hide()

        // フォームが本番かdemoか
        this.isProd = this.thisFormParams[0].indexOf('demo_') == -1
        const demo = this.isProd ? "" : "Demo"
        const ApiKey = formDataStore.state.apis[`loginKey${demo}`]
        const spreadsheetApi = formDataStore.state.apis[`spreadsheet${demo}`]
        const calCreateApi = formDataStore.state.apis[`calCreate${demo}`] + "/request_shift"
        const calUpadteApi = formDataStore.state.apis[`calUpdate${demo}`]+ "/request_shift"
        const dbSelectApi = formDataStore.state.apis[`dbApiURL${demo}`] + "/db/select"

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }

        // slack チャンネル URL
        this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id

        this.groupID = this.slackChannel.id
        this.postID = this.functions.getPostID("", this.slackChannel.userID, this.slackChannel.id)

        document.documentElement.scrollTop = 0

        const userData = formDataStore.state.userData
        var fullname = userData.firstName + " " + userData.lastName
        var user_name = formDataStore.state.userData.firstName
        //直接このページへ飛ぶなどして名前がない場合はトップ画面へ飛ばす
        if (this.userName == "" || this.userName == null) {
          this.requestFunc.allReset(this.clCount,this.delCount)
          location.href = '/';
        } 

        if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
          fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
          user_name = userData.BN
        }

        let user_id = ""

        // 同じコンポーネントに遷移するときの初期化はここまで
        // // if(this.initialized) return

        //祝日日付取得
        const Api = holidayApi
        const token = "AiVVRa6ieuAyjBe"
        const from_date = "2023-01-01"
        const URL = Api + "?token=" + token + "&from_date=" + from_date //https://tn-post-341508.an.r.appspot.com/?token=AiVVRa6ieuAyjBe&from_date=2023-01-01

        fetch(URL)
        .then(response => response.json())
        .then(data => { 
          
          $.each(data.holiday, (index, value) => {
            let today = new Date(value) 
            let day = this.requestFunc.dayAdjustment(today)
            this.csvData.push(day) 
          })

        }) 

        //日付初期設定
        const showTime = () => {
          let today = new Date() 
          today.setDate(today.getDate()) 
          let min = this.requestFunc.dayAdjustment(today)
          $("#start_date")[0].min = min 
          $("#last_date")[0].min = min 
          $("#requestDate")[0].min = min 
          $("#adjustmentRequestDate")[0].min = min
          $("#workDate1")[0].min = min 
        }
        $("#start_date").innerHTML = showTime()

        //開始日付設定
        $('#start_date').off('click.start_date').on('click.start_date',function() {
          $("#start_date")[0].max = $("#last_date")[0].value 
        }) 

        //終了日付設定
        $('#last_date').off('click.last_date').on('click.last_date',() => {
          let today = new Date() 
          today.setDate(today.getDate()) 
          let min = this.requestFunc.dayAdjustment(today)
          let stmin = $("#start_date")[0].value 

          if (stmin > min) {
            $("#last_date")[0].min = stmin 
          } else if (stmin == min) {
            $("#last_date")[0].min = min 
          }

        }) 

        //開始日付手入力時の逆転防止
        $('#start_date').off('change.start_date').on('change.start_date',() =>  {
          let today = new Date() 
          today.setDate(today.getDate()) 
          let min = this.requestFunc.dayAdjustment(today)

          if ($("#start_date")[0].value > $("#last_date")[0].value && $("#last_date")[0].value != "") {
            $("#start_date")[0].value = "" 
          }

          if ($("#start_date")[0].value < min) {  //当日より遡りも防止
            $("#start_date")[0].value = "" 
          }

        }) 

        //終了日付手入力時の逆転防止
        $('#last_date').off('change.last_date').on('change.last_date',() => {

          if ($("#last_date")[0].value < $("#start_date")[0].value) {
            $("#last_date")[0].value = "" 
          }

        }) 

        //休日出勤日付手入力時の当日より遡り防止
        $(document).off("change.workDate").on("change.workDate", ".workDate", (e) => {
          let target = $(e.target)
          let today = new Date() 
          today.setDate(today.getDate()) 
          let min = this.requestFunc.dayAdjustment(today)

          if (target[0].value < min) {
            target[0].value = "" 
          }

        })

        //代理投稿時の氏名欄表示
        $(document).off("change.agent").on("change.agent", ".agent", () => {

          if ($("#agent:checked").val()) {
            $('#agentName').addClass('required')
            $(".agentName").show()

          } else {
            $("#agentName").val("")
            $(".agentName").hide()
            this.functions.removeRequiredOp("agentName")
            $('.ListFollower, .msg_loading').remove()
          }

        })

        $(document).off("input.agentName").on("input.agentName", ".agentName", () => {
          const agentName = $('#agentName').val()

          if(that.intervalObj) {
            clearInterval(that.intervalObj)
            if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
            that.abortController = new AbortController()
          }

          $('.ListFollower, .msg_loading').remove()
          $('<label class="pd5_rl msg_loading">検索中(_ _).｡o○</label>').appendTo("#agentName")

          if(agentName == "") {
            $('.ListFollower, .msg_loading').remove()
            return false
          }

          that.intervalObj = setTimeout(() => {
            that.abortController = new AbortController()

            const params = {
              authC: {
                password: "W!z|a~.x.T~Tzca8CBCm",
                mail: "system@sungrove.co.jp"
              },
              main_data: {
                tables:["users"],
                query: `SELECT * FROM users WHERE (first_name LIKE "%${agentName}%" OR business_name LIKE "%${agentName}%") AND deleted_at IS NULL`
              },
              support_data: {
                is_prod: that.isProd
              }
            }

            const color = '#e91e63'

            // Loading表示
            that.viewLoader('同行者検索中')

            fetch(dbSelectApi, {
              signal: that.abortController.signal,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-Cal-St-Api': ApiKey
              },
              body: JSON.stringify(params)
            })
            .then(response => response.json())
            .then(response => {

              const mes = $('.msg_loading')
              let text = ''
              const res = typeof response == 'String'? response - 0 : response

              switch(res) {
                case 0: {
                  if(response[0].user_id !== '#N/A') {
                    mes.remove()
                    this.functions.createFollowerList(response)
                  } else {
                    text = '検索できませんでした。検索ワードをもう一度確認してから検索してください。'
                  }
                  break
                }
                case 1: {
                  text = 'アクセスが許可されていません。'
                  break
                }
                case 2: {
                  text = 'リクエストデータがありません。'
                  break
                }
                case 3: {
                  text = '認証が通りませんでした。'
                  break
                }
                case 4: {
                  text = '検索結果が50件を越えています。検索結果を絞るために文字を追加してください。'
                  break
                }
                case 99: {
                  text = '何らかのエラーが発生しました。'
                  break
                }
              }

              if(text) {
                mes.text(text).css('color', color)
                alert(text)
              }

              if(response.length > 0 && response[0].user_id !== '#N/A') {
                mes.remove()
                this.functions.createFollowerList(response)
              }

              // Loading非表示
              that.viewLoader()

            })
            .catch(error => {
              console.log({error})
              // Loading非表示
              that.viewLoader();
              if(error.name != "AbortError") {
                $('.msg_loading').text('エラー').css('color', color)
              }
            })
          }, 1000)
        })

        $(document).off('change', 'input[name="ListFollower"]').on('change', 'input[name="ListFollower"]', function() {
          let selectedValue = $(this).val()
          const pattern = /\([^)]*\)/
          selectedValue = selectedValue.replace(pattern, '')
          user_id = $(this).data('user').user_id
          $("#agentName").val(selectedValue)
        })


        //必須項目常に監視
        $(document).off('change.requestForms input.requestForms click.requestForms mousemove.requestForms').on('change.requestForms input.requestForms click.requestForms mousemove.requestForms', '#requestForms', () => {
          let resultRequiredArray = this.functions.checkRequired()
          this.requestFunc.lastCheck(resultRequiredArray)
        })

        //フォームタイプ選択
        $('#requestForms').off('change.requestFormsTypes').on('change.requestFormsTypes',() => {
          
          const elms = ["btnForm_Request", "btnForm_Change", "btnForm_Cancel", "btnRequest_Rest", "btnRequest_HoliWork", "btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "compensatoryHoliday", "compensatoryHolidayCall", "btnTime_AllDay", "btnTime_AM", "btnTime_PM", "start_date", "requestDate", "cancelDate"]

          $.each(elms, function(index, value) {
            $('#' + value).addClass('required')
          })

          let remain = [] 
          remain = this.requestFunc.checkClone(remain, this.clCount)

          $.each(remain, function(index, value) {
            $("#holidayWorkBtn_a" + value).addClass('required')
            $("#holidayWorkBtn_b" +value).addClass('required')
            $("#holidayWorkBtn_c" +value).addClass('required')
            $("#workDate" + value).addClass('required')
            $("#start_time_id" + value).addClass('required')
          })

          let remarksVal = $('#remarks').val()
          const radioButtons = document.querySelectorAll('input[name="requestForm"]')
          $('#reason').addClass('required')
          $('#changes_id').addClass('required')
          $('#cancel_detail_id').addClass('required')
          $('.remarks, .pochiButton').show()
          $('.requestKindsBox, .requestKinds').show()
          const removeValueAdjustment = ["compensatoryHoliday", "compensatoryHolidayCall", "SubjectId", "reason_adjustment", "adjustmentRequestDate", "start_time_id_adjustment", "last_time_id_adjustment", "start_time_id_adjustment_face", "last_time_id_adjustment_face", "start_time_id_adjustment_online", "last_time_id_adjustment_online",
          ]
          const hideAdjustment = ["adjustment"]
          const removeRequireAdjustment = ["compensatoryHoliday", "compensatoryHolidayCall", "SubjectId", "btnRequest_adjustment", "reason_adjustment", "adjustmentRequestDate","start_time_id_adjustment_face", "last_time_id_adjustment_face", "start_time_id_adjustment_online", "last_time_id_adjustment_online", "start_time_id_adjustment", "last_time_id_adjustment",
          ]
          this.requestFunc.resetHide(hideAdjustment)
          this.requestFunc.resetRemoveRequired(removeRequireAdjustment)

          switch (formTypeId.value) {

            case "申請":
              const req = $("[name=requestForm]")
              const inputReq = this.requestFunc.inputButton(req)
              const arrayHideRequest = ["requestKinds", "requestStart", "requestDay", "holidayWork", "changesForm", "cancelForm"]
              const arrayValueRequest = ["changes_id", "cancel_detail_id", "cancelDate"]
              const arrayRequireRequest = ["changes_id", "cancel_detail_id", "cancelDate", "SubjectId", "salesdate"]
              this.requestFunc.resetHide(arrayHideRequest)
              this.requestFunc.resetValue(arrayValueRequest)
              this.requestFunc.resetRemoveRequired(arrayRequireRequest)
              $('.requestType').show()
              this.cancelDate = ''
              this.eventData = []
              this.btnLabel = '要望を送信'

              switch (inputReq) {

                case "おやすみ申請":
                  const arrayHideRest = ["requestStart", "requestDay", "holidayWork", "alert", "hide_holiday"]
                  const arrayRequireRest = ["btnForm_Change", "btnForm_Cancel", "btnRequest_HoliWork"]
                  
                  arrayValueRequest.push("cancelDate")
                  arrayRequireRequest.push("cancelDate")
                  this.requestFunc.resetHide(arrayHideRest)
                  this.requestFunc.resetRemoveRequired(arrayRequireRest)
                  $('.requestKindsBox, .requestKinds').show()

                  $.each(remain, (index, value) => {
                    const arrayCheckedRest = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "allDay" + value]
                    const arrayValueRest = ["workDate" + value, "start_time_id" + value, "last_time_id" + value, "reason_adjustment", "adjustmentRequestDate", "start_time_id_adjustment", "last_time_id_adjustment", "start_time_id_adjustment_face", "last_time_id_adjustment_face", "start_time_id_adjustment_online", "last_time_id_adjustment_online"]
                    const arrayRequiredRest = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "workDate" + value, "start_time_id" + value]
                    this.requestFunc.resetChecked(arrayCheckedRest)
                    this.requestFunc.resetValue(arrayValueRest)
                    this.requestFunc.resetRemoveRequired(arrayRequiredRest)
                    const target = $("#form_ap" + value)
                    this.delCount += this.requestFunc.deleteClone(this.delCount, this.clCount, target)
                  })

                  const timeBtn = $("[name=timeKinds]")
                  const timeKinds = this.requestFunc.inputButton(timeBtn)
                  this.requestFunc.restRequest(timeKinds)

                  if ($('#btnReqKind_ComHoliday').is(':checked')) {
                    $('#reason_holiday').hide()
                    $('#reason, #reason option.required').removeClass('required')
                    if ($('#compensatoryHoliday').is(':checked')) {
                      $('#SubjectId, #salesdate').addClass("required")
                    }
                  } else {
                    $('#searchID').hide()
                  }
                  break 

                case "休日出勤":
                  const arrayCheckedHoliWork = ["btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay","btnTime_AM", "btnTime_PM", "compensatoryHoliday", "compensatoryHolidayCall"]
                  const arrayValueHoliWork = ["start_date", "last_date", "SubjectId", "requestDate", "reason", "reason_adjustment", "adjustmentRequestDate", "start_time_id_adjustment", "last_time_id_adjustment", "start_time_id_adjustment_face", "last_time_id_adjustment_face", "start_time_id_adjustment_online", "last_time_id_adjustment_online"]
                  const arrayHideHoliWork = ["requestKinds", "compensatoryHolidayTypeBox", "searchID", "requestStart", "requestDay"]
                  const arrayRequireHoliWork = ["btnRequest_Rest", "btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay", "btnTime_AM", "btnTime_PM", "start_date", "requestDate", "reason", "compensatoryHoliday", "compensatoryHolidayCall"]
                  arrayValueRequest.push("cancelDate")
                  arrayRequireRequest.push("cancelDate")

                  this.requestFunc.resetChecked(arrayCheckedHoliWork)
                  this.requestFunc.resetValue(arrayValueHoliWork)
                  this.requestFunc.resetHide(arrayHideHoliWork)
                  this.requestFunc.resetRemoveRequired(arrayRequireHoliWork)
                  $('.holidayWorkBox, .holidayWork').show()
                  let check = 0 

                  $.each(remain, (index, value) => {
                    this.requestFunc.checkBoxStyle(value)
                    check += this.requestFunc.checkHoliday(value, this.csvData)
                    this.requestFunc.checkTimeValue(value, this.clCount)
                  })

                  if (check > 0) {
                    $('.alert').show()
                  } else if (check == 0) {
                    $('.alert').hide()
                  }

                  break 

                case "アポ調整依頼（平日のみ）":
                  const arrayCheckedAdjustment = ["btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay","btnTime_AM", "btnTime_PM", "compensatoryHoliday", "compensatoryHolidayCall"]
                  const arrayValueAdjustment = ["start_date", "last_date", "SubjectId", "requestDate", "reason"]
                  const arrayHideAdjustment = ["compensatoryHolidayTypeBox", "searchID", "requestStart", "requestDay", "holidayWork", "alert", "requestKinds"]
                  const arrayRequireAdjustment = ["btnRequest_Rest", "btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay", "btnTime_AM", "btnTime_PM", "start_date", "requestDate", "reason", "btnForm_Change", "btnForm_Cancel", "btnRequest_HoliWork", "compensatoryHoliday", "compensatoryHolidayCall"]
                  arrayValueRequest.push("cancelDate")
                  arrayRequireRequest.push("cancelDate")

                  this.requestFunc.resetChecked(arrayCheckedAdjustment)
                  this.requestFunc.resetValue(arrayValueAdjustment)
                  this.requestFunc.resetHide(arrayHideAdjustment)
                  this.requestFunc.resetRemoveRequired(arrayRequireAdjustment)
                  $('.adjustmentBox, .adjustment').show()
                  $('#reason_adjustment, #adjustmentRequestDate').addClass('required')

                  $.each(remain, (index, value) => {
                    const arrayCheckedRest = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "allDay" + value]
                    const arrayValueRest = ["workDate" + value, "start_time_id" + value, "last_time_id" + value]
                    const arrayRequiredRest = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "workDate" + value, "start_time_id" + value]
                    this.requestFunc.resetChecked(arrayCheckedRest)
                    this.requestFunc.resetValue(arrayValueRest)
                    this.requestFunc.resetRemoveRequired(arrayRequiredRest)
                    const target = $("#form_ap" + value)
                    this.delCount += this.requestFunc.deleteClone(this.delCount, this.clCount, target)
                  })

                  const date = $("#adjustmentRequestDate").val()
                  const newDate = new Date(date)
                  const week = newDate.getDay()
                  $('.alert_adjustment').hide()

                  if (week == 0 || week == 6) {
                    $('.alert_adjustment').show()
                    $("#adjustmentRequestDate").val("")
                  }

                  const timeBtnAdjustment = $("[name=timeKinds]")
                  const timeKindsAdjustment = this.requestFunc.inputButton(timeBtnAdjustment)
                  this.requestFunc.restRequest(timeKindsAdjustment)

                  this.requestFunc.checkTimeValue("_adjustment")
                  const removeRequireArray = ["start_time_id_adjustment_face", "last_time_id_adjustment_face", "start_time_id_adjustment_online", "last_time_id_adjustment_online", "start_time_id_adjustment", "last_time_id_adjustment"]
                  this.requestFunc.resetRemoveRequired(removeRequireArray)

                  const checkbox = document.getElementById('adjustment_sales_type')
                  if (checkbox.checked) {
                    $(".sales_type_on").show()
                    $(".sales_type_off").hide()
                    this.requestFunc.handleTimeValidation.call(this, "#start_time_id_adjustment_face", "#last_time_id_adjustment_face", ["start_time_id_adjustment_face", "last_time_id_adjustment_face"])
                    this.requestFunc.handleTimeValidation.call(this, "#start_time_id_adjustment_online", "#last_time_id_adjustment_online", ["start_time_id_adjustment_online", "last_time_id_adjustment_online"])

                  } else {
                    $(".sales_type_off").show()
                    $(".sales_type_on").hide()
                    this.requestFunc.handleTimeValidation.call(this, "#start_time_id_adjustment", "#last_time_id_adjustment", ["start_time_id_adjustment", "last_time_id_adjustment"])
                  }
              }
              break 

            case "変更":
              const textValChange = $('#changes_id').val()
              this.requestFunc.allReset(this.clCount, this.delCount)
              this.clCount = 1 
              this.delCount = 0 
              this.cancelDate = ''
              $('#remarks').val(remarksVal)
              $('#changes_id').val(textValChange)
              $("#formTypeId").val("変更")
              $('.changesForm').show()
              $('.remarks').show()
              $('.pochiButton').show()
              $('#changes_id').addClass('required')
              $('.ListFollower, .msg_loading').remove()
              this.requestFunc.resetValue(removeValueAdjustment)
              this.requestFunc.resetHide(["compensatoryHolidayTypeBox", "searchID"])
              this.requestFunc.resetChecked(["compensatoryHoliday", "compensatoryHolidayCall"])
              radioButtons.forEach(radio => { radio.checked = false})
              this.requestFunc.resetRemoveRequired(["cancelDate"])
              break 

            case "取り消し（休日・休日出勤）":
              const textValCancel = $('#cancel_detail_id').val()
              this.requestFunc.allReset(this.clCount, this.delCount)
              this.clCount = 1 
              this.delCount = 0 
              $('#remarks').val(remarksVal)
              $('#cancel_detail_id').val(textValCancel)
              $("#formTypeId").val("取り消し（休日・休日出勤）")
              $('.cancelForm').show()
              $('.remarks').show()
              $('.pochiButton').show()
              $('#cancel_detail_id').addClass('required')
              $('.ListFollower, .msg_loading').remove()
              this.requestFunc.resetValue(removeValueAdjustment)
              this.requestFunc.resetHide(["compensatoryHolidayTypeBox", "searchID"])
              this.requestFunc.resetChecked(["compensatoryHoliday", "compensatoryHolidayCall"])
              radioButtons.forEach(radio => { radio.checked = false})

              if(!this.cancelEventID) {
                this.btnLabel = 'カレンダー検索'
              } 

              break 

          }

        }) 

        //休日出勤申請フォームクローン追加ボタン
        $(document).off("click.form_add").on("click.form_add", ".form_add", (e) => {
          this.clCount += 1 
          const _this = e.target
          const $beforeRadioTag = $(_this).parent().parent().parent().find('input[type="radio"]')
          const beforeRadioValue = $(_this).parent().parent().parent().find('input[type="radio"]:checked').val()
          const clone = '.cloneEle' + this.clCount
          this.$cloneTag = $(_this).parent().parent().parent().clone(true)
          this.$cloneTag.removeClass().addClass('cloneEle' + this.clCount).insertAfter($(_this).parent().parent().parent()).find('.addDelText').val('')
          $(clone).attr('id', 'form_ap' + this.clCount)
          this.$operationTimeTag = $(`#form_ap${this.clCount}`)
          $(clone + ' input[type="radio"]').attr('name', 'sellFormat' + this.clCount)
          $(clone + ' label[id="btnLabel_a"]').attr('for', 'holidayWorkBtn_a' + this.clCount)
          $(clone + ' label[id="btnLabel_b"]').attr('for', 'holidayWorkBtn_b' + this.clCount)
          $(clone + ' label[id="btnLabel_c"]').attr('for', 'holidayWorkBtn_c' + this.clCount)
          $(clone + ' input[value="対面"]').attr('id', 'holidayWorkBtn_a' + this.clCount)
          $(clone + ' input[value="オンライン"]').attr('id', 'holidayWorkBtn_b' + this.clCount)
          $(clone + ' input[value="どちらでも可"]').attr('id', 'holidayWorkBtn_c' + this.clCount)
          $(clone + ' input[name="start_time"]').attr('id', 'start_time_id' + this.clCount)
          $(clone + ' input[name="last_time"]').attr('id', 'last_time_id' + this.clCount)
          $(clone + ' input[type="checkbox"]').attr('id', 'allDay' + this.clCount)
          $(clone + ' input[type="date"]').attr('id', 'workDate' + this.clCount)
          this.requestFunc.checkTime(this.$operationTimeTag, this.clCount)

          if (beforeRadioValue == "対面") {
            $beforeRadioTag.eq(0).prop("checked", true)
          } else if (beforeRadioValue == "オンライン") {
            $beforeRadioTag.eq(1).prop("checked", true)
          } else if (beforeRadioValue == "どちらでも可") {
            $beforeRadioTag.eq(2).prop("checked", true)
          }

          const arrayCheckedClone = ["holidayWorkBtn_a" + this.clCount, "holidayWorkBtn_b" + this.clCount, "holidayWorkBtn_c" + this.clCount, "allDay" + this.clCount] 
          const arrayValueClone = ["workDate" + this.clCount, "start_time_id" + this.clCount, "last_time_id" + this.clCount]
          this.requestFunc.resetChecked(arrayCheckedClone)
          this.requestFunc.resetValue(arrayValueClone)
          this.requestFunc.checkBoxStyle(this.clCount)
        })

        //クローン削除ボタン
        $(document).on("click", ".form_del", (e) => {
          const _this = e.target
          let target = $(_this).parent().parent().parent()
          this.delCount += this.requestFunc.deleteClone(this.delCount, this.clCount, target)
        })

        $(document).on('change', "input[name='restType']", function() {
          if ($('#btnReqKind_ComHoliday').is(':checked')) {
            $('.compensatoryHolidayTypeBox').show()
            $('#compensatoryHoliday, #compensatoryHolidayCall').addClass("required")
            $('#reason_holiday').hide()
            $('#reason, #reason option.required').removeClass('required')
          } else {
            $('.compensatoryHolidayTypeBox').hide()
            $('#compensatoryHoliday, #compensatoryHolidayCall').removeClass("required")
            $('#compensatoryHolidayType input[type="radio"]').prop('checked', false)
            $('#reason_holiday').show()
            $('#reason, #reason option.required').addClass('required')
          }
        })

        $(document).on('change', "input[name='compensatoryHolidayType']", function() {
          if ($('#compensatoryHoliday').is(':checked')) {
            $('#searchID').show()
            $('#SubjectId, #salesdate').addClass("required")
            $("#compensatoryHoliday + span, #compensatoryHolidayCall + span").css("background-color", "")
          } else {
            $('#searchID').hide()
            $('#SubjectId, #salesdate').removeClass("required")
            $("#compensatoryHoliday + span, #compensatoryHolidayCall + span").css("background-color", "")
          }
        })

        let searchedID = false

        $(document).on('input', "#SubjectId", function() {
          const subjectId = $('#SubjectId').val()
          if (searchedID && subjectId.length != 27) {
            searchedID = false
            $('#result_titles').empty()
            $('#salesdate').val('')
          }
          if (subjectId.length == 27) {
            $('#SubjectId').attr('readonly', true)
            const searchApiURL = formDataStore.state.apis.searchSubjectsIDApiURL + subjectId
            $('#result_titles').empty()
            $('#salesdate').val('')
            that.viewLoader('検索中')
            fetch(searchApiURL)
            .then(response => response.json())
            .then(response => {
              let responsePrevious = response.filter(item => item.description && item.description.indexOf("掘り起こし元ID：" + subjectId) !== -1)
              let newID = ""
              if (responsePrevious.length > 0) {
                newID = responsePrevious[0].description.split("案件ID：")[1].split("\n")[0]
                newID = "掘り起こし後のID候補：" + newID
                alert("掘り起こし済みIDです！ IDを確認してください！")
                response = []
              } else {
                response = response.filter(item => item.description && item.description.indexOf("案件ID：" + subjectId) !== -1)
              }
              $('#SubjectId').attr('readonly', false)
              searchedID = true
              that.viewLoader()

              if(response.length >= 1) {
                $('#status_comment').text('該当カレンダーのタイトルを選択してください。').css('color', '#29b6f6')
                const errorText = that.functions.createSubjectList(response)
                if (errorText != []) {
                  $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
                }
              } else {
                $('#status_comment').html('もう一度IDを確認して検索してください。<br>' + newID).css('color', '#e91e63')
              }
            })
          }
        })

        // 複数案件ヒット時の切替処理
        $(document).on('change', "input[name='ListEvents']", function() {
          const dataAbout = $(this).attr('data-about')
          const parsedData = JSON.parse(dataAbout)
          $('#salesdate').val(parsedData.mtg_date)
        })

        //ポチボタンを押したときの処理
        $('#btn').off('click.btn').on('click.btn', (e) => {
          let form = "" 
          let text = "" 
          let agent = ""
          let subject = ""
          let subjectDate = ""
          let startDate = "" 
          let lastDate = "" 
          let start = "" 
          let last = "" 
          let lastmsg = "" 
          let lastTimemsg = [] 
          let startTime = [] 
          let lastTime = [] 
          let day = "" 
          let dayList = [] 
          let dateDisplay = "" 
          let comment = "" 
          let sellStyle = [] 
          let allday =  [] 
          let alldaymsg = [] 
          let rest = "" 
          let annotation = "" 
          let answer = "" 
          let formKinds = "" 
          let restType = "" 
          let restmsg = "" 
          let chromeDayList = [] 
          let dateInt = [] 
          let holidayWorkList = [] 


          switch (formTypeId.value) {

            case "申請":
              const formBtn = $("[name=requestForm]")
              formKinds = this.requestFunc.inputButton(formBtn)

              switch (formKinds) {

                case "おやすみ申請":
                  form ="【おやすみ申請"
                  dateDisplay = "日付："
                  comment = "理由：" 
                  text = reason.value 

                  if (start_date.value !== "") {
                    startDate = this.requestFunc.getDate(start_date.value) 
                    start = this.requestFunc.getWeek(start_date.value) 
                  }

                  if (last_date.value !== "") {
                    lastDate = this.requestFunc.getDate(last_date.value) 
                    last = this.requestFunc.getWeek(last_date.value) 
                    lastmsg = "～  " + this.requestFunc.getWeek(last_date.value)
                  }

                  if (requestDate.value !== "") {
                    startDate = this.requestFunc.getDate(requestDate.value) 
                    start = this.requestFunc.getWeek(requestDate.value) 
                  }

                  const time = $("[name=timeKinds]")
                  rest = this.requestFunc.inputButton(time) 
                  restmsg = rest
                  let type = $("[name=restType]")
                  restType = this.requestFunc.inputButton(type)
                  form = form + "（" + restType + "）】"

                  if (last_date.value !== "") {
                    restmsg = "" 
                    allday = "[終日]" 
                  }

                  const selectedRestType = $('input[name="restType"]:checked').val()
                  const selectedCompensatoryHoliday = $('#compensatoryHoliday').is(':checked')

                  if (selectedRestType == "代休") {
                    if (selectedCompensatoryHoliday) {
                      text = "土日祝商談の代休"
                      subject = "代休理由の案件ID：" + $('#SubjectId').val()
                      subjectDate = "案件日付：" + $('#salesdate').val()
                    } else {
                      text = "架電業務の代休"
                    }
                  }
                
                  break 

                case "休日出勤":
                  let remain = [] 
                  remain = this.requestFunc.checkClone(remain, this.clCount)
                  let i = -1 

                  $.each(remain, (index, value) => {
                    i++
                    day = $("#workDate" + value).val()
                    dayList[i] = this.requestFunc.getWeek(day)
                    chromeDayList[i] = this.requestFunc.getDate(day)
                    dateInt[i] = this.requestFunc.getDateInt(day)
                    startTime[i] = $("#start_time_id" + value).val()
                    lastTime[i] = $("#last_time_id" + value).val()
                    lastTimemsg[i] = " ～ " + $("#last_time_id" + value).val()
                    const sellFormatText = $("[name=sellFormat" + value + "]")
                    const inputSell = this.requestFunc.inputButton(sellFormatText)
                    sellStyle[i] = inputSell 
                    form = "【休日出勤申請】" 
                    allday[i] = "" 
                    alldaymsg[i] = "" 

                    if ($("#allDay" + value + ":checked").val()) {
                      allday[i] = "終日" 
                      alldaymsg[i] = "[終日]" 
                      startTime[i] = "" 
                      lastTime[i] = "" 
                      lastTimemsg[i] = "" 
                    }
                  
                  })

                  break

                case "アポ調整依頼（平日のみ）":
                  const checkbox = document.getElementById('adjustment_sales_type');

                  if (checkbox.checked) {
                    let start_face = $("#start_time_id_adjustment_face").val()
                    let last_face = $("#last_time_id_adjustment_face").val()
                    let start_online = $("#start_time_id_adjustment_online").val()
                    let last_online = $("#last_time_id_adjustment_online").val()
                    comment = start_face + "～" + last_face + " 🏃 、" + start_online + "～" + last_online + " 🎦 "
                  } else {
                    let start = $("#start_time_id_adjustment").val()
                    let last = $("#last_time_id_adjustment").val()
                    comment = start + " ～ " + last
                  }

                  form ="アポ調整依頼"
                  dateDisplay = "日付："
                  text = "理由：" + reason_adjustment.value
                  startDate = this.requestFunc.getDate(adjustmentRequestDate.value) 

              }
              break 

            case "変更":
              form = "【変更】" 
              comment = "変更内容：" 
              text = changes_id.value 
              annotation = "★★手動対応★★\r\n手動で上記内容のカレンダー反映お願いします。尚、当文は自動入力です。" 
              break 

            case "取り消し（休日・休日出勤）":
              form = "【取り消し（休日・休日出勤）】" 
              comment = "取り消し（休日・休日出勤）" 
              annotation = "" 
              break 
          }

          if ($("#agent:checked").val()) {
            agent = "※代理投稿：[" + $("#agentName").val() + "]さんの名前でカレンダーに反映されます"
          }

          const reqForm = $("[name=requestForm]")
          const inputReqForm = this.requestFunc.inputButton(reqForm)
          let checkPostFlg = "" 

          if (inputReqForm == "おやすみ申請") {
            checkPostFlg = window.confirm("この内容で投稿しますか？\r\n  " + dateDisplay + allday + start + lastmsg + restmsg + "\r\n  " + comment + text + "\r\n  " + agent + "\r\n  " + subject + "\r\n  " + subjectDate)
            answer = form + agent +
                    "\r\n" + dateDisplay + allday + start + lastmsg + restmsg +
                    "\r\n" + comment + text +
                    "\r\n" + subject +
                    "\r\n" + subjectDate +
                    "\r\n" + annotation +
                    "\r\n" + "-----------------------------"

          } else if (inputReqForm == "休日出勤") {
            let disDate = "" 
            let n = this.clCount - this.delCount 

            for (let i = 0; i < n; i++) {
              let num = i + 1 
              disDate = disDate + "\r\n   (" + num + ")\r\n   日付：" + dayList[i] + alldaymsg[i] + startTime[i] + lastTimemsg[i] + "\r\n   商談形式指定：" + sellStyle[i] + "\r\n" 

              holidayWorkList[i] = {
                date: dayList[i],
                chrome_date: chromeDayList[i],
                date_int: dateInt[i],
                all_date: allday[i],
                start_time: startTime[i],
                last_time: lastTime[i],
                meeting_type: sellStyle[i]
                }
            }
            
            checkPostFlg = window.confirm("この内容で投稿しますか？\r\n" + disDate + "\r\n  備考：" + remarks.value + "\r\n" + agent)
            answer = form + agent +
                    "\r\n" + disDate +
                    "\r\n備考：" + remarks.value +
                    "\r\n" + "-----------------------------" 

          } else if (inputReqForm == "アポ調整依頼（平日のみ）") {

            checkPostFlg = window.confirm("この内容で投稿しますか？\r\n  " + dateDisplay + startDate + " "  + comment + "\r\n  " + text + "\r\n" + agent)
            answer = form + agent +
                    "\r\n" + dateDisplay + startDate + " "  + comment +
                    "\r\n" + text +
                    "\r\n" + "-----------------------------" 

          } else if (formTypeId.value == "取り消し（休日・休日出勤）") {
   
            const mes = !this.cancelEventID? 'この内容で検索します': 'この内容で取り消します'
            const remark = this.cancelEventID? "\r\n  備考：" + remarks.value + "\r\n": '' 

            text = '' 
            
            if(this.cancelEventID) {
              text = `取り消し内容： ${this.cancelDate} ${this.selectedText}
              イベントID: ${this.cancelEventID.replace(/_sub[0-9]+/,'')}
              内容： ${cancel_detail_id.value}` 
            } else {
              text = `検索日： ${this.cancelDate}`
            }

            checkPostFlg = window.confirm(mes + "\r\n  " + dateDisplay + allday + start + lastmsg + restmsg + "\r\n  " + comment + text + remark + agent)

            answer = form + agent +
                    "\r\n" + dateDisplay + allday + start + lastmsg + restmsg +
                    "\r\n" + comment + text +
                    "\r\n備考：" + remarks.value +
                    "\r\n" + annotation +
                    "\r\n" + "-----------------------------" 

          } else {

            checkPostFlg = window.confirm("この内容で投稿しますか？\r\n  " + dateDisplay + allday + start + lastmsg + restmsg + "\r\n  " + comment + text + "\r\n  備考：" + remarks.value + "\r\n" + agent)
            answer = form + agent +
                    "\r\n" + dateDisplay + allday + start + lastmsg + restmsg +
                    "\r\n" + comment + text +
                    "\r\n備考：" + remarks.value +
                    "\r\n" + annotation +
                    "\r\n" + "-----------------------------" 

          }

          //送信用メッセ
          moment.locale("ja")

          let agentName =$("#agentName").val()
          const groupName = "requestShift"
          const NowTime = this.requestBodyFunc.nowTime()
          const created_at = this.requestBodyFunc.nowTime("created_at")

          let inputReq = this.requestFunc.inputButton($("[name=requestForm]"))
          let timeKinds = this.requestFunc.inputButton($("[name=timeKinds]"))
          let count = this.clCount - this.delCount

          if (formTypeId.value == "変更" || formTypeId.value == "取り消し（休日・休日出勤）") {
            inputReq = ""
            agentName = ""
          }

          if (inputReqForm != "おやすみ申請") {
            restType = ""
            timeKinds = ""
          } 
          
          if (inputReqForm != "休日出勤") {
            dayList[0] = ""
            sellStyle[0] = ""
            startTime[0] = ""
            lastTime[0] = ""
          }

          if (checkPostFlg) {
  
            
            (async () => {
              
              let userID = user_id == "" ? that.store.state.userData.id : user_id
              const formatStartDate = startDate.replace(/\//g, '-')
              const formatLastDate = lastDate.replace(/\//g, '-')

              let holidayWorkListForCal = holidayWorkList

              for (let i = 0; i < holidayWorkListForCal.length; i++) {
                holidayWorkListForCal[i].chrome_date =  holidayWorkListForCal[i].chrome_date.replace(/\//g, '-')
              }
              
              let calData = { 
                "sg_data": {
                            "is_prod": that.isProd,
                            "created_at": created_at, 
                            "group_id":  that.slackChannel.id,
                            "user_id": userID,
                            "user_name": user_name
                            },
                "content": { 
                            "work_type": formKinds,
                            "holiday_work_list": holidayWorkListForCal,
                            "start_date": formatStartDate,
                            "rest_type": restType,
                            "rest_time_type": rest,
                            "last_date": formatLastDate,
                            "adjustment_time": comment,
                            "agent_name": agentName
                            }
              }


              // 休日・休日出勤日検索
              let updatedCalLink = '' 
              if(this.cancelDate) {
                if(!this.cancelEventID) {
                  await that.searchCalender(calData, calUpadteApi, ApiKey)
                  return
                } else {
                  updatedCalLink = await that.upadteCalanderEvent(calData, calUpadteApi, ApiKey)
                }
              } else {
                //Loading表示
                this.viewLoader('投稿中')
              }

              //スプレッドシート登録へ送信 フォームタイプが「申請」の時のみで「変更」「取り消し」の際はＧＡＳへは情報を送信しない
    
              // slack送信
              const messageURL = await that.sendToSlack(answer, fullname)
              .then((data) => {
                return data
              })

              //スプレッドシートに登録

              let sheetDataAry = {
                type: "RequestShift",
                user_id: this.userID,
                post_id: this.postID,
                post_url: messageURL,
                group_id: this.groupID,
                msg: answer,
                formTypeName: formTypeId.value,
                work_type: formKinds,
                holiday_work_list: holidayWorkList,
                start_date: start,
                chrome_start_date: startDate,
                rest_type: restType,
                rest_time_type: rest,
                last_date: last,
                chrome_last_date: lastDate,
                reasons: text,
                other_things: remarks.value,
                message: answer,
                agentName: agentName
              }

              let insertDataRecordCnt = []
              
              for (let i = 0; i < count ; i++) {

                if (allday[i] == "終日") {
                  startTime[i] = "終日"
                }

                insertDataRecordCnt[i] = {
                                          "投稿日時":{"id": "", "val": NowTime},
                                          "全文": {"id": "", "val": sheetDataAry.msg},
                                          "投稿URL":{"id": "", "val": messageURL},
                                          "投稿ID":{"id": "", "val": sheetDataAry.post_id},
                                          "ユーザーID":{"id": "", "val": sheetDataAry.user_id},
                                          "投稿者名":{"id": "", "val": fullname},
                                          "被代理者名":{"id": "", "val": sheetDataAry.agentName},
                                          "申請フォーム":{"id": "", "val": formTypeId.value},
                                          "申請タイプ":{"id": "", "val": inputReq},
                                          "お休み種別":{"id": "", "val": sheetDataAry.rest_type},
                                          "お休み時間":{"id": "", "val": timeKinds},
                                          "開始日付":{"id": "", "val": startDate},
                                          "終了日付":{"id": "", "val": lastDate},
                                          "商談日":{"id": "", "val": dayList[i]},
                                          "商談形式":{"id": "", "val": sellStyle[i]},
                                          "開始時間":{"id": "", "val": startTime[i]},
                                          "終了時間":{"id": "", "val": lastTime[i]},
                                          }
              }
            
              //スプレッドシートに登録
              let sheetData = this.requestBodyFunc.createBody(groupName, that.isProd, insertDataRecordCnt)
              console.log(sheetData)

              switch (formTypeId.value) {
                
                case "申請":
                  const spreadRequest = this.requestBodyFunc.apiRequestExecution(spreadsheetApi, sheetData, ApiKey)
                  const calRequest = this.requestBodyFunc.apiRequestExecution(calCreateApi, calData, ApiKey)
                  Promise.all([spreadRequest, calRequest])
                    .then((response) => {
                      // 完了画面表示
                      let message = "カレンダーへの反映は後ほど行われます。"
                    
                      let responseData = response[1].json()
                      responseData.then(result => {
                        // Loading非表示
                        that.viewLoader()
                        //画面リセット
                        this.requestFunc.allReset(this.clCount, this.delCount)
                        this.clCount = 1 
                        this.delCount = 0 

                        let calURL = result[0].htmlLink
   
                        // 完了画面表示
                        const params = {
                          groupTitle: this.groupTitle,
                          groupURL: this.groupURL,
                          searchURL: messageURL,
                          calURL: calURL,
                          message: message,
                          completeTitle: '投稿完了'
                        }
                        that.viewComplete(params)

                      })
                    }).catch((e) => {
                      console.error(e.stack, e.message)
                    })
                    break 

                case "変更":
                case "取り消し（休日・休日出勤）":

                  await fetch(spreadsheetApi, {
                    method : "POST",
                    headers: {
                      "Content-Type": "application/json",
                      'X-Cal-St-Api': ApiKey
                    },
                    body: JSON.stringify(sheetData)
                  }).then((response) => {
                    // Loading非表示
                    that.viewLoader()
                    //画面リセット
                    this.requestFunc.allReset(this.clCount, this.delCount)
                    this.clCount = 1 
                    this.delCount = 0 

                    // 完了画面表示
                    const params = {
                      groupTitle: this.groupTitle,
                      groupURL: this.groupURL,
                      searchURL: messageURL,
                      calURL: updatedCalLink,
                      message: '',
                      calendarMessage: '変更されたカレンダーはこちら',
                      completeTitle: '投稿完了'
                    }
                    that.viewComplete(params)

                  }).catch((e) => {
                    console.error(e.stack, e.message)
                  })
                  break

                default:
                  console.error("フォームタイプが異常です")

              }
            
            })() 
      
          }
      
        }) 

        // 初期化完了フラグ
        this.initialized = true
        console.log('initialized')
      },

      // slack投稿
      async sendToSlack(answer, fullname) {
        // slack 投稿者にメンション

        const slackMention = `<@${this.slackChannel.userID}>\n`

        answer =  '[' + this.version + ']' +
                  '\r\n' + answer +
                  '\r\n' + 'POST ID：' + this.postID +
                  '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

        // slack投稿
        const data = {
          "channel": this.slackChannel.id, 
          "username": fullname,
          "text":  slackMention + answer, 
          "token": this.slackChannel.token,
          "icon_url": this.slackChannel.iconURL
        }

        const response = await fetch(this.slackChannel.postURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
        })
        .then(function (response) {
          return response.json()
        }).then(function (response) {

          if(response.message == "not current version") {
            alert(`バージョンが違うようです。\n更新します。`);
            location.reload()
            postResult = false
            return false
          } else {
            
            console.log('slack投稿完了', response)

            return response
          }

        })
        .catch((e) => {
          console.error(e.stack, e.message)
        })    

        return `${this.groupURL}/p${response.ts.split('.').join('')}`
      },

      // カレンダー検索
      async searchCalender(calData, calUpadteApi, ApiKey) {
        calData.content.cancel_date = this.cancelDate
        this.viewLoader('カレンダー検索中')
        const calSearchRequest = this.requestBodyFunc.apiRequestExecution(calUpadteApi, calData, ApiKey)

        calSearchRequest
        .then((response) => response.json())
        .then((data) => {
          // Loading非表示
          this.viewLoader()

          if(data.length > 0) {
            this.eventData = data

            if(this.eventData.length > 1) {
              this.cancelDateDescription = '取り消しする内容を選択してください'
              this.btnLabel = '選択したので再送信する'
            } else {
              this.cancelDateDescription = '取り消しする内容を確認、選択してください'
              this.btnLabel = '確認したのでOK送信'
            }
          } else {
            alert('選択日に登録イベントがありませんでした')
          }

        }).catch((e) => {
          console.error(e.stack, e.message)
        })
        return
      },

      async upadteCalanderEvent(calData, calUpadteApi, ApiKey) {
        calData.content.event_id = this.cancelEventID.replace(/_sub[0-9]+/, '')

        if(this.selectedText) {
          calData.content.time = this.selectedText
        }

        this.viewLoader('カレンダー更新中')
        const calUpdRequest = this.requestBodyFunc.apiRequestExecution(calUpadteApi, calData, ApiKey)

        return await calUpdRequest
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          // Loading非表示
          //this.viewLoader()
          return data.htmlLink
        }).catch((e) => {
          console.error(e.stack, e.message)
        })
        return
      },

      getText(event) {
        // 選択されたラジオボタンのtext属性の値を取得
        this.selectedText = event.target.getAttribute('text')
      },

      viewLoader(loaderText = '') {
        this.isLoading = !this.isLoading
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle
        this.groupURL = param.groupURL
        this.searchURL = param.searchURL
        this.message = param.message
        this.calendarMessage = param.calendarMessage
        this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了'

        if (param.calURL == "") {
          this.message = "カレンダーへは反映されませんので手動での変更をお願いします。"
        } else {
          this.calURL = param.calURL
        }

        this.isBody = false
        this.isComplete = true
        console.log('完了画面表示')
      },
    },

    watch: { },
    
    //ページ表示したタイミングで実行されるJSを記述 
    mounted: function() {
      this.store = formDataStore    

      if(this.store.state.groupList.length == 0) {
        // このフォームに直接飛んできたときにフォーム情報取得が完了してないことがあるので同期待ち
        console.log('watch')
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            console.log('watch init')
            this.init()
          }
        )
      } else {
        console.log('watch init')
        this.init()
      }

    },

    // 同じコンポーネント内で遷移するので以下の記述をした
    /*async beforeRouteUpdate(to, from, next) {
      await this.init()
      await next()
    },*/

  }) 

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
//このモジュールで使用するCSSをSCSS形式で記述する
</style>
