
/*
* ぽちボタンクリック後の処理
*/ 

class afrPostItzai {
  constructor(that) {
    this.that = that
    this.formName = "イツザイ"
    const $ = this.that.$
    const store = this.that.store
    
    if(store.state.industryNamesData.length == 0) {
      this.that.vueObj.viewLoader('業界リスト読み込み中')
    }
    
    let i = 0
    const sit = setInterval(() => {
      
      if(store.state.industryNamesData.length > 0) {
        clearInterval(sit)
        this.that.vueObj.viewLoader()

        // データ出力先有無をチェック
        if($('#industry_names')[0]) {
          const industry_records = store.state.industryNamesData
          
          for(let i = 0; i < industry_records.length; i++) {
            let record = industry_records[i]
            let option = $('<option>')

            option.attr({
              id: 'jobsCategoriesID' + record.id,
              value: record.name,
              'data-id': record.id
            }).text(record.name)
            
            $('#industry_names').append(option).show()
          }

        }

      } else {
        i++
        if(i >= 200) {
          clearInterval(sit)
          alert('業界リストを取得できませんでした\nリロードします')
          window.location.reload()
        }
      }
      
    }, 50)


    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    const motiveSigningContractValue = Array.from(
      document.querySelectorAll('input[name="motive_signing_contract"]:checked')
    ).map(input => input.value)

    const persnalityValue = Array.from(
      document.querySelectorAll('input[name="persnality"]:checked')
    ).map(input => input.value)

    const promiseInsertionValue = Array.from(
      document.querySelectorAll('input[name="promise_insertion"]:checked')
    ).map(input => input.value)

    const promiseContentsValue = Array.from(
      document.querySelectorAll('input[name="promise_contents"]:checked')
    ).map(input => input.value)

    // 値取得：制作前共有
    store.commit('setValue', ['itzai', 'inputShouldMovie', $('[name="movie_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputPlan', $('[name="itzai_plan"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputBrandPage', $('[name="Brand_page_itzai"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputPRPagesProduced', func.textEscape($('#PR_pages_produced_itzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputRequirementsProduced', func.textEscape($('#recruitment_requirements_produced_itzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputKVProduced', func.textEscape($('#KV_produced_itzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputRctJobCate', func.textEscape($('#rctJobCate').val()), ''])
    store.commit('setValue', ['itzai', 'inputCompensation', $('[name="compensation_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputOtherOp', func.textEscape($('#otherOp').val()), ''])
    store.commit('setValue', ['itzai', 'inputShouldShoot', $('[name="shoot_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputReasonNotShoot', func.textEscape($("#reasonNotShoot").val()), ''])
    store.commit('setValue', ['itzai', 'inputMotiveSigningContract', motiveSigningContractValue, ''])
    store.commit('setValue', ['itzai', 'inputContractReason', func.textEscape($('#contractReason').val()), ''])
    store.commit('setValue', ['itzai', 'inputPersnality', persnalityValue, ''])
    store.commit('setValue', ['itzai', 'inputConv', func.textEscape($('#conv').val()), ''])
    store.commit('setValue', ['itzai', 'inputPromiseInsertion', promiseInsertionValue, ''])
    store.commit('setValue', ['itzai', 'inputConvItzai', func.textEscape($('#convItzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputPromiseContents', promiseContentsValue, ''])
    store.commit('setValue', ['itzai', 'inputConvService', func.textEscape($('#convService').val()), ''])
    store.commit('setValue', ['itzai', 'inputShootDate', func.textEscape($('#shootDate').val()), ''])
    store.commit('setValue', ['itzai', 'inputRctBackground', func.textEscape($('#rctBackground').val()), ''])
    store.commit('setValue', ['itzai', 'inputKindPersonHope', func.textEscape($('#kind_person_Hope').val()), ''])
    store.commit('setValue', ['itzai', 'inputOtherHope', func.textEscape($('#otherHope').val()), ''])
    store.commit('setValue', ['itzai', 'inputContactItzai', func.textEscape($('#contact_itzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputMainContactItzaiPresence', $('[name="main_contact_itzai_presence"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputJobOfferCampaign', func.textEscape($('[name="guide_job_offer_campaign"]:checked').val()), ''])

    // v2.0.1
    store.commit('setValue', ['itzai', 'inputContactCEOName', $("#contactCEOName").val(), 'contact_ceo_name'])
    store.commit('setValue', ['itzai', 'inputJobCnt', $("#job_cnt").val(), ''])
    store.commit('setValue', ['itzai', 'inputPackedCnt', $('#packed_cnt').val(), '']) 
    store.commit('setValue', ['itzai', 'inputIndustryName', $('#industry_names').val(), 'industry_name'])
    store.commit('setValue', ['itzai', 'inputIndustryDataId', $('#industry_names option:selected').data("id"), 'industry_data_id'])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store
    const inputBrandPage = store.state.formData.itzai.inputBrandPage ? store.state.formData.itzai.inputBrandPage : ""

    let sendVal = 
`
◎イツザイ
動画撮影：${store.state.formData.itzai.inputShouldMovie}
プラン：${store.state.formData.itzai.inputPlan}
ブランドページ：${inputBrandPage}
PRページ制作代行本数：${store.state.formData.itzai.inputPRPagesProduced}
募集要項制作代行数：${store.state.formData.itzai.inputRequirementsProduced}

KV追加制作代行数：${store.state.formData.itzai.inputKVProduced}
補償オプション：${store.state.formData.itzai.inputCompensation}
その他オプション：${store.state.formData.itzai.inputOtherOp}

募集業界：${store.state.formData.itzai.inputIndustryName}
募集職種名：${store.state.formData.itzai.inputRctJobCate}

撮影有無：${store.state.formData.itzai.inputShouldShoot}
撮影無し理由：${store.state.formData.itzai.inputReasonNotShoot}

契約動機：${store.state.formData.itzai.inputMotiveSigningContract}
契約動機詳細：${store.state.formData.itzai.inputContractReason}

顧客人柄：${store.state.formData.itzai.inputPersnality}
お客様との会話内容：${store.state.formData.itzai.inputConv}

イツザイに載せる内容について話した内容：${store.state.formData.itzai.inputPromiseInsertion}
上記の詳細：${store.state.formData.itzai.inputConvItzai}

サービス内容について提案した内容：${store.state.formData.itzai.inputPromiseContents}
上記の詳細：${store.state.formData.itzai.inputConvService}

掲載希望日時：${store.state.formData.itzai.inputShootDate}
求人背景：${store.state.formData.itzai.inputRctBackground}
どういう人が取りたいか：${store.state.formData.itzai.inputKindPersonHope}
その他顧客希望：${store.state.formData.itzai.inputOtherHope}
`

    const main_contact_yes_no = document.querySelector('input[name="main_contact_itzai"]:checked')
    if (main_contact_yes_no.value == "はい") {
      sendVal = sendVal + `
連絡先情報備考（主要窓口）：${store.state.formData.itzai.inputContactItzai}
商談同席有無：${store.state.formData.itzai.inputMainContactItzaiPresence}
`
    }

    sendVal = sendVal + `
求人ボックスキャンペーン対応：${store.state.formData.itzai.inputJobOfferCampaign}
`
    store.commit('setTalknoteMessage', ['itzai', sendVal])
  }

  actionInit() {
    const $ = this.that.$
    
    $("#form_ap").on("click", '[name="shoot_yesNo"]', function() {
      
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes":
          $("#fildReasonNotShoot").slideUp()
          $("#reasonNotShoot").removeClass("required")
          $("#reasonNotShoot").val("")
          break;
      
        case "shoot_no":
          $("#fildReasonNotShoot").slideDown()
          $("#reasonNotShoot").addClass("required")
          break;
      }
    })

    $("#form_ap").on("change", '[name="itzai_plan"]', function() {

      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "itzai_plan_super_lite":
        case "itzai_plan_semi_lite":
          $("#brand_page").slideUp()
          $("[name='Brand_page_itzai']").removeClass("required")
          $("[name='Brand_page_itzai']").prop("checked", false)
          break;

        case "itzai_plan_lite":
        case "itzai_plan_standard":
        case "itzai_plan_complete":
          $("#brand_page").slideDown()
          $("[name='Brand_page_itzai']").addClass("required")
          break;
      }
    })

    $("#form_ap").on("change", '[name="main_contact_itzai"]', function() {

      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "main_contact_itzai_no":
          $("#is_contact_itzai_concurrent, #is_contact_itzai_presence").slideUp()
          $("#contact_itzai, [name='main_contact_itzai_presence']").removeClass("required")
          $("#contact_itzai").val("")
          $("[name='main_contact_itzai_presence']").prop("checked", false)
          break;

        case "main_contact_itzai_yes":
          $("#is_contact_itzai_concurrent, #is_contact_itzai_presence").slideDown()
          $("#contact_itzai, [name='main_contact_itzai_presence']").addClass("required")
          break;
      }
    })
  }

/*  setParams() {
    const $ = this.that.$
    const vueObj = this.that.vueObj
    const props = this.that.props
    const func = this.that.func
    const store = this.that.store
    const getDataApiURL = this.that.getDataApiURL

    let params = {
      //msg: store.state.msg,
      contact_com_name: this.values.inputContactComName,
      contact_name: this.values.inputContactName,
      contact_mail: this.values.inputContactMail,
      contact_tel_num: this.values.inputContactTelNum,
    };

    return params
  } */
}

export default {
  afrPostItzai 
}